import React from 'react';
import { GetThemeValue,  SetThemeValue  } from '../../services/Utils';
import card1 from '../../assets/images/images-v3/Prepare-&-Stimulate-card1.png';
import card2 from '../../assets/images/images-v3/Relax-&-Recover-card2.png';
import card3 from '../../assets/images/images-v3/Soothe-and-Relieve-card3.png';
import card4 from '../../assets/images/images-v3/Promote-Relaxation-card4.png';
import mobilegreen from '../../assets/images/images-v3/mobile-green.jpg';
import mobileblue from '../../assets/images/images-v3/mobile-blue1.jpg';
import NaturalsHeader from '../../components/theme/naturals/NaturalsHeader';
import bluecard1 from '../../assets/images/images-v3/Prepare-&-Stimulate-blue-card1.svg';
import bluecard2 from '../../assets/images/images-v3/Relax-&-Recover-blue-card2.svg';
import bluecard3 from '../../assets/images/images-v3/Soothe-and-Relieve-blue-card3.svg';
import bluecard4 from '../../assets/images/images-v3/Promote-Relaxation-bluecard4.svg';
import qrBlueImgLocal from '../../assets/images/images-v3/qr-blue.svg';
import qrGreenImgLocal from '../../assets/images/images-v3/qr-img.svg';
import qrBlueImgQA from '../../assets/images/images-v3/qa/qrblue.png';
import qrGreenImgQA from '../../assets/images/images-v3/qa/qrgreen.png';
import qrBlueImgUAT from '../../assets/images/images-v3/uat/qrblue.png';
import qrGreenImgUAT from '../../assets/images/images-v3/uat/qrgreen.png';

import qrBlueImgLive from '../../assets/images/images-v3/live/qrblue.png';
import qrGreenImgLive from '../../assets/images/images-v3/live/qrgreen.png';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const NaturalsDesktopLanding = () => {
  const { t } = useTranslation('naturals');
  const [themeName, setThemeName] = React.useState('');
  const [backButton] = React.useState('no');
  const [headerEnabled] = React.useState(true);
  const [searchParams] = useSearchParams();
  const [qrCode,setQrcode] = React.useState('');
  React.useEffect(() => {
    let themeValue = searchParams.get('theme');
    SetThemeValue(themeValue);
    setThemeName(GetThemeValue());
    if(window.location.href.indexOf('nextlotus-uat.azurewebsites.net')> -1)
    {
        if(themeName === 'green')
        {
          setQrcode(qrGreenImgUAT);
        }
        else if(themeName === 'blue')
        {
          setQrcode(qrBlueImgUAT);
        }
    }
    else if(window.location.href.indexOf('mylotus.care')> -1)
    {
        if(themeName === 'green')
        {
          setQrcode(qrGreenImgLive);
        }
        else if(themeName === 'blue')
        {
          setQrcode(qrBlueImgLive);
        }
    }
    else if(window.location.href.indexOf('nextlotus-qa.azurewebsites.net')> -1)
    {
      if(themeName === 'green')
      {
        setQrcode(qrGreenImgQA);
      }
      else if(themeName === 'blue')
      {
        setQrcode(qrBlueImgQA);
      }
    }
    else 
    {
      if(themeName === 'green')
      {
        setQrcode(qrGreenImgLocal);
      }
      else if(themeName === 'blue')
      {
        setQrcode(qrBlueImgLocal);
      }
    }
  });
  return (
    <> <div className={'lotus-'+themeName}>
          <div className="bg-landing">
            <NaturalsHeader deviceType={'desktop'} headerEnabled={headerEnabled} theme={themeName} headerClass={'landing'} backButton={backButton}/>
              <div className="home-mid-container bg-inner-page"> 
                  <div className="top-curve "></div>
                  <div className="qr-app-container">
                 <div className="qr-app">
           
                  <div className="qr-container">
                    <div className="qr-heading">
                     { t('desktopLanding.heading')}</div>
                      <p>{ t('desktopLanding.subHeading')}</p>
                  <div className="qr-img">
                  <img src={qrCode} alt="Qr code"></img>
                  </div>
                  </div>
                 
                  <div className="mobile-container">
                  <img src={themeName==='green'? mobilegreen: mobileblue} alt="Mobile phone with app open on the landing page"></img>
                  </div>
                
                 </div>
                 </div>
                 <div className="landing-card">
<div className="landing-card-heading">What to expect</div>
<div className="card-container">
  <div className="desktop-card">
  {themeName==='green' ? ( <div className="desktop-card-img"><img src={card1} alt="Photograph of a man and a woman ready to race on an athletic track"></img></div>):('')}
    <div className="card-titile">{ t('introSlider.one.heading')}</div>
    <p>{ t('introSlider.one.body')}</p>
    {themeName==='blue' ? (   <div className="desktop-card-img-blue"><img src={bluecard1} alt="Illustration of a shield"></img></div>):('')}
      
  </div>
  <div className="desktop-card">
   {themeName==='green' ?( <div className="desktop-card-img"><img src={card2} alt="Photograph of a mature woman meditating outside"></img></div>):('')}
    <div className="card-titile">{ t('introSlider.two.heading')}</div>
    <p>{ t('introSlider.two.body')}</p>
      {themeName==='blue' ? (<div className="desktop-card-img-blue"><img src={bluecard2} alt="Illustration of a brain"></img></div>):('')}
  </div>
  <div className="desktop-card">
   {themeName==='green' ? (<div className="desktop-card-img"><img src={card3} alt="Photograph of a happy woman freely moving in nature"></img></div>):('')}
    <div className="card-titile">{ t('introSlider.three.heading')}</div>
    <p>{ t('introSlider.three.body')}</p>
   {themeName==='blue' ? (<div className="desktop-card-img-blue"><img src={bluecard3} alt="Illustration of a relaxed person"></img></div>):('')}
  </div>
  <div className="desktop-card">
   {themeName==='green' ? ( <div className="desktop-card-img"><img src={card4} alt="Photograph of a happy man in a city wearing earphones"></img></div>):('')}
    <div className="card-titile">{ t('introSlider.four.heading')}</div>
    <p>{ t('introSlider.four.body')}</p>
     {themeName==='blue' ? ( <div className="desktop-card-img-blue"><img src={bluecard4} alt="Illustration of music notes"></img></div>):('')}
  </div>
</div>
                 </div>
                  
              </div>
          </div>
        </div>
           
    </>
  );
};
export default NaturalsDesktopLanding;
