import React from 'react';
import { SetSessionView } from '../../services/Session';
import {pushInGoggleTag} from '../../services/DataLayerGa';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
const OhImprovement = (propsValue) => {
  const [show, setShow] = React.useState(true);
  const [iEnjoyingBrushError, setIEnjoyingBrushError] = React.useState('');
  const [iBrushFor, setIBrushFor] = React.useState('');
  const [iBrush, setIBrush] = React.useState('');
  const [iEnjoyBrushing, setIEnjoyBrushing] = React.useState('');
  const [iBrushForError, setIBrushForError] = React.useState('');
  const [iBrushError, setIBrushError] = React.useState('');
  const closeWindowData = () => {
    setShow(false);
  };
  const updateFeedBack = (feedBackData, fieldName) => {
    if (fieldName === 'IBRUSHFOR') {
      setIBrushFor(feedBackData);
      setIBrushForError('');
    } else if (fieldName === 'IBRUSH') {
      setIBrush(feedBackData);
      setIBrushError('');
    } else if (fieldName === 'IENJOYINGBRUSH') {
      setIEnjoyBrushing(feedBackData);
      setIEnjoyingBrushError('');
    }
  };

  function finalFeedBack() {
    if (iBrushFor === '') {
      setIBrushForError(t('ohlimprovement.errBrush'));
    }
    if (iBrush === '') {
      setIBrushError(t('ohlimprovement.errBrush1'));
    }
    if (iEnjoyBrushing === '') {
      setIEnjoyingBrushError(t('ohlimprovement.errBrush2'));
    }
    if (iBrushFor !== '' && iBrush !== '' && iEnjoyBrushing !== '') {
      SetSessionView('ohImprovementFeedback', {
        iBrushFor: iBrushFor,
        iBrush: iBrush,
        iEnjoyBrushing: iEnjoyBrushing
      });
      pushInGoggleTag({
        popupQuestion:t('ohlimprovement.modelTitle'),
        'event': 'popup_feedback',
        popupFeedback:'popupFeedback',
        feedbackName:"ohImprovement",
        feedbackValue:iBrushFor + ' - ' + iBrush +  '-' + iEnjoyBrushing
       },'V2');
      propsValue.updateSelectedValue(propsValue.currentSelectedSession);
      setShow(false);
    }
  }
  const { t } = useTranslation();
  return (
    <>
      <Modal className="brushingModel" show={show} onHide={closeWindowData}>
        <Modal.Header closeButton>
          <Modal.Title>{t('ohlimprovement.modelTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="btn-heading">{t('ohlimprovement.brushFor')}</div>
          <div className="btn-section">
            <button
              className={
                iBrushFor === 'Longer' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="firstQ1"
              key="firstQ1"
              onClick={() => updateFeedBack('Longer', 'IBRUSHFOR')}>
              {t('ohlimprovement.button.Longer')}
            </button>
            <button
              className={
                iBrushFor === 'Shorter' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="secondQ1"
              key="secondQ1"
              onClick={() => updateFeedBack('Shorter', 'IBRUSHFOR')}>
              {t('ohlimprovement.button.Shorter')}
            </button>
            <button
              className={
                iBrushFor === 'Neither' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="thirdQ1"
              key="thirdQ1"
              onClick={() => updateFeedBack('Neither', 'IBRUSHFOR')}>
              {t('ohlimprovement.button.Neither')}
            </button>
          </div>

          <div className="btn-heading">{t('ohlimprovement.text')}</div>
          <div className="btn-section">
            <button
              className={
                iBrush === 'More Frequently' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="firstQ2"
              key="firstQ2"
              onClick={() => updateFeedBack('More Frequently', 'IBRUSH')}>
              {t('ohlimprovement.button.MoreFrequently')}
            </button>
            <button
              className={
                iBrush === 'Less Frequently' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="secondQ2"
              key="secondQ2"
              onClick={() => updateFeedBack('Less Frequently', 'IBRUSH')}>
              {t('ohlimprovement.button.LessFrequently')}
            </button>
            <button
              className={
                iBrush === 'Neither' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="thirdQ2"
              key="thirdQ2"
              onClick={() => updateFeedBack('Neither', 'IBRUSH')}>
              {t('ohlimprovement.button.Neither')}
            </button>
          </div>

          <div className="btn-heading">{t('ohlimprovement.brushHeader')}</div>
          <div className="btn-section">
            <button
              className={
                iEnjoyBrushing === 'More' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="firstQ4"
              key="firstQ4"
              onClick={() => updateFeedBack('More', 'IENJOYINGBRUSH')}>
              {t('ohlimprovement.button.More')}
            </button>
            <button
              className={
                iEnjoyBrushing === 'Less' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="secondQ4"
              key="secondQ4"
              onClick={() => updateFeedBack('Less', 'IENJOYINGBRUSH')}>
              {t('ohlimprovement.button.Less')}
            </button>
            <button
              className={
                iEnjoyBrushing === 'Neither' ? 'pop-button-ohimpact active' : 'pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="thirdQ4"
              key="thirdQ4"
              onClick={() => updateFeedBack('Neither', 'IENJOYINGBRUSH')}>
              {t('ohlimprovement.button.Neither')}
            </button>
          </div>
          <span className="text-color-danger"> {iBrushForError}</span>
          <span className="text-color-danger">{iBrushError}</span>
          <span className="text-color-danger">{iEnjoyingBrushError}</span>
          <div className="btn-center">
            <button className="popup-feedback btn btn-primary mar-0" onClick={() => finalFeedBack()}>
              {t('ohlimprovement.button.Submit')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default OhImprovement;
