import React, { useState, useEffect } from 'react';
import popup1 from '../../assets/images/lotus-phone-illustration.svg';
import { Modal } from 'react-bootstrap';
import { SetLocalStorage } from '../../services/Session';
//import { useAddToHomescreenPrompt } from './AddToHomeScreen';
import { useTranslation } from 'react-i18next';
import popup2 from '../../assets/images/product-phone-illustration.svg';
import tapShare from '../../assets/images/tap-share-icon.svg';
import homeShare from '../../assets/images/add-home-icon.svg';
const AppIcon = (propsValue) => {
  const [show, setShow] = React.useState(true);
  const [isAppIos, setIsAppIos] = useState(false);
  const [contentData, setContentData] = useState('false');
  const [imageName, setimageName] = useState('false');
  const { t } = useTranslation();

  function addShortcut(){
    SetLocalStorage('appIcon', 'yes');
    propsValue.promptToInstall();
    setShow(false);
  }
  function changeModel() {
    SetLocalStorage('appIcon', 'yes');
    propsValue.addHomeScreen();
    setShow(false);
  }
  
  useEffect(() => {
    if (propsValue.popTemplate === 'appIconView') {
      setContentData(t('appIcon.iconText'));
      setimageName(popup1);
    } else {
      setContentData(t('appIcon.qrCodeText'));
      setimageName(popup2);
    }
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    // Checks if should display install popup notification:
    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsAppIos(true);
      setShow(true);
    }
    // if are standalone android OR safari
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      // hidden the button
      changeModel();
    }
    // do action when finished install
    window.addEventListener('appinstalled', e => {
      e.preventDefault();
      changeModel();
      //console.log("success app install!");
    });
  });

  return (
    <>
      <Modal className="brushingModel" show={show} onHide={changeModel}>
      <Modal.Header closeButton>
          <Modal.Title>{t('appIcon.modelTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{contentData}</p>
          <img className="popup1" alt="APPICON" src={imageName} />
          <div className="btn-center mar-0">
            {isAppIos ? (
              <div className="addhome" >
                <div className="tapShare"><div className="add-heading">In your browser:</div></div>
                <div className="tapShare">
                 <img src={tapShare} /> <p>{t('appIcon.iosText')}</p>
                </div>
                <div className="tapShare">                
                  <img src={homeShare} /> <p>{t('appIcon.iosText2')} </p>
                </div>
              </div>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => addShortcut()}>
                {t('appIcon.button')}
              </button>
            )}
          </div>
          </Modal.Body>
      </Modal>
    </>
  );
};
export default AppIcon;
