import React from 'react';
import VoltaNaturalLanding from '../../components/VoltaNaturalLanding';
const NaturalsLanding = (propsValue) => {
  React.useEffect(() => {
  });
  return (
    <>
           <VoltaNaturalLanding theme={propsValue.theme} />
           
    </>
  );
};
export default NaturalsLanding;
