import React from 'react';
import clamintinelogo from '../assets/images/images-v3/clementine-logo1.svg';
import tubegel from '../assets/images/images-v3/gel-tube.png';
import mic from '../assets/images/images-v3/home-icon.svg';
import { GetThemeValue  } from '../services/Utils';
import { defualtPage  } from '../services/WebsiteUrl';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const VoltaNaturalLanding = (propsValue) => {
  const { t } = useTranslation('naturals');
  const navigate = useNavigate();
    const [theme,setTheme] = React.useState(propsValue.theme);
  React.useEffect(() => {
    setTheme(GetThemeValue());
  });
  const introPage = () => {
    let returnData = defualtPage('v3Intro'); 
    navigate(returnData);
  };
  return (
  <>
  <div className={theme === 'green'? "home-content position-relative" : 'hide-div'}>
      
          <div className="home-heading position-absolute">{t('landing.ptext1')}</div>
          
      </div>
      <div className="home-body-content">

      <div className={theme === 'blue'? "home-heading" : 'hide-div'}>{t('landing.ptext1')}</div>
      
              <p>{t('landing.ptext2')}
              </p>
           <div className={theme === 'green'? "d-flex justify-content-center" : 'hide-div'}>
              <button
                  type="button" onClick={() => introPage()}
                  className="btn btn-primary btnwidth90 text-uppercase btposition">
                 {t('landing.ptext4')} 
              </button>
          </div>
          <div className="gel-bg position-relative">
              <div className="gel-tube">
              <img   src={theme === 'green'? tubegel : mic } alt={theme === 'green'? 'Photograph of VoltarolNaturals tube':'Illustration of relaxed person'} ></img>
             
               </div> 
               <div className={theme === 'blue'? "landing-btn-pos d-flex justify-content-center" : 'hide-div'}>
              
              <button
                  type="button" onClick={() => introPage()}
                  className="btn btn-primary btnwidth90 btn-position-home">
                  {t('landing.ptext3')}
              </button>
          </div>             
            </div>
           
            </div>
           
          <div className={theme === 'green'? "footer d-flex justify-content-center" : 'hide-div'} >
             <p>{t('landing.ptext5')}</p><div className="footer-logo"><img src={clamintinelogo} alt="clamintine"></img></div>
        </div>

        <div className={theme === 'blue'? "footer" : 'hide-div'} >
            {/* <div className="row">
             <p>{t('landing.ptext5')}</p>
             </div> */}
             <div className="d-flex justify-content">
             <p className="top-padding">{t('landing.ptext5')}</p>
              {/* <p className="top-padding">&</p> */}
             <div className="footer-logo top-padding"><img src={clamintinelogo} alt="Clamintine"></img></div>
           {/*  <div className="footer-logo"><img src={VoltarolNaturals} alt="Voltarol Naturals"></img></div> */}
             </div>
             
    </div>        
        </>

  );
};
export default VoltaNaturalLanding;
