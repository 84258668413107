import { useState, useEffect } from 'react';

export default function UseWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    systemtype: undefined,
    orientation:'portrait'
  });
  useEffect(() => {
    // Handler to call on window resize
   /* function checkOrientation(){
      if (window.matchMedia("(orientation: portrait)").matches) {
        console.log('orientation: portrait');
      }
  
      if (window.matchMedia("(orientation: landscape)").matches) {
        console.log('orientation: landscape');
       }
    }*/
    function handleResize() {
      // Set window width/height to state
      let systemType = false;
      const { userAgent } = window.navigator;
      if (
        userAgent.match(/Android/i) ||
        userAgent.match(/webOS/i) ||
        userAgent.match(/iPhone/i) ||
        userAgent.match(/iPad/i) ||
        userAgent.match(/iPod/i) ||
        userAgent.match(/BlackBerry/i) ||
        userAgent.match(/Windows Phone/i)
      ) {
        systemType = true;
      }
      if((window.innerHeight < window.innerWidth) && ( window.innerWidth === undefined || window.innerWidth < 800) ){ 
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          systemtype: systemType,
          orientation:'landscape'
        });
      }
      else
      {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          systemtype: systemType,
          orientation:'portrait'
        });
      }

      
    }
  //  window.addEventListener('orientationchange', checkOrientation);
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}
