import React from 'react';
import { useTranslation } from 'react-i18next';
import haleonlogo from '../../assets/images/images-v3/Haleon-logo.svg';
const NaturalsTermUse = () => {
  const { t } = useTranslation('naturals');
  React.useEffect(() => {
  });
  return (
    <>
     <div className="terms">
     <img src={haleonlogo} alt="Haleon logo"></img>
     <div className="heading">{t('voltaTermc.heading1')}</div>
     <div className="term-heading">{t('voltaTermc.updatedDate')}</div>
     <p>{t('voltaTermc.p1text1')}</p>
      <p>{t('voltaTermc.p1text2')}</p>
      <div className="term-heading">{t('voltaTermc.heading2')}</div>
      <p>{t('voltaTermc.p2text1')}</p>
      <div className="term-heading">{t('voltaTermc.heading3')}</div>
     <p>{t('voltaTermc.p3text1')} <a href="https://www.haleon.com/contact-us/general-enquiry/" target="_blank">{t('voltaTermc.form')}</a> </p>
     <div className="term-heading">{t('voltaTermc.heading4')} </div>
      <p>{t('voltaTermc.p4text1')}<a href="https://www.privacy.haleon.com/" target="_blank">
        {t('voltaTermc.p4anchor')}</a>. {t('voltaTermc.p4text1remain')}</p>
        <div className="term-heading"> {t('voltaTermc.heading5')}</div>
        <p>{t('voltaTermc.p5text1')}</p>
        <div className="term-heading">{t('voltaTermc.heading6')}</div>
          <p>{t('voltaTermc.p6text1')} </p>

            <div className="term-heading">{t('voltaTermc.heading7')}</div>
            <p>{t('voltaTermc.p7text1')}</p>
            <p>
            {t('voltaTermc.p7text2')}
            </p>
            <div className="term-heading">{t('voltaTermc.heading8')}</div>
<p>{t('voltaTermc.p8text1')}
</p>
<p>{t('voltaTermc.p8text2')}
</p>
<p>{t('voltaTermc.p8text3')}
</p>
<div className="term-heading">{t('voltaTermc.heading9')}</div>
<p>{t('voltaTermc.p9text1')} </p>
  <div className="term-heading">{t('voltaTermc.heading10')}</div>
  <p>{t('voltaTermc.p10text1')} </p>
    <div className="term-heading">{t('voltaTermc.heading11')}</div>
    <p>{t('voltaTermc.p11text1')}</p>
<p>{t('voltaTermc.p11text2')}
</p>
<div className="term-heading">{t('voltaTermc.heading12')}</div>
<p>{t('voltaTermc.p12text1')} </p>
  <div className="term-heading">{t('voltaTermc.heading13')}</div>
  <p>{t('voltaTermc.p13text1')}
    </p>
    <p>
    {t('voltaTermc.p13text2')}
  </p>
                  
    </div>
    </>
  );
};

export default NaturalsTermUse;
