import React from 'react';
import HeaderGreen from './lotus-green/HeaderGreen';
import HeaderBlue from './lotus-blue/HeaderBlue';
import PageHeadTitle from './PageHeadTitle'
import { useNavigate } from 'react-router-dom';
const NaturalsHeader = (propsValue) => {
  const navigate = useNavigate();
  const backButtonHandler = () => {
         navigate(-1);
  };
  React.useEffect(() => {
  //  alert(propsValue.headerEnabled);
  });
  if(propsValue.headerEnabled === false)
  {
      return (
        <>
       <PageHeadTitle backButtonHandler={backButtonHandler}/>
      
        </>
      );
  }
  else
  {
        if(propsValue.theme == 'green')
        {
          return (
            <>
              <HeaderGreen deviceType={propsValue.deviceType} backButtonHandler={backButtonHandler} headerClass={propsValue.headerClass} backButton={propsValue.backButton}/>
            </>
          );
        }
        else
        {
          return (
            <>
            <HeaderBlue  deviceType={propsValue.deviceType} backButtonHandler={backButtonHandler} headerClass={propsValue.headerClass} backButton={propsValue.backButton}/>
            </>
          );
        }
  }
};
export default NaturalsHeader;
