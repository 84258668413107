import React from 'react';
import Emotions from '../../components/Emotions';
import { GetVoltaraNaturalFeedback, SetVoltaraNaturalFeedBack } from '../../services/Session';
import { defualtPage } from '../../services/WebsiteUrl';
import { useNavigate } from 'react-router-dom';
const NaturalsReflection = () => {
  const navigate = useNavigate();
  const [videoName,setVideoName] = React.useState('');
  React.useEffect(() => {
    let feedBackData = GetVoltaraNaturalFeedback();
    if(feedBackData != null && feedBackData != undefined  )
    {       
      let pendingData = JSON.parse(feedBackData);
      setVideoName(pendingData.pendingVideo);
    }
  },[]);
  const finalFeedBack = (emotionsFeedBackList,emotionsFeedback) => {
    SetVoltaraNaturalFeedBack({
      pendingVideo:videoName,
      emotions: emotionsFeedBackList.toString(),
      otheremotions: emotionsFeedback,
      subtitles: 'on'
    });
    let returnData = defualtPage('v3Slider');
    navigate(returnData+'?id='+videoName);
  };
  if(videoName !== '' )
{
  return (
    <>
    <div className="emotion-bg"><Emotions finalFeedBack={finalFeedBack} />
    </div>
    </>
  );
}
};

export default NaturalsReflection;
