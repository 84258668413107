import { BrowserRouter,Route, Routes } from 'react-router-dom';
import React from 'react';
//import BrushingRoute from './routes/BrushingRoute';
import LotusRoute from './routes/LotusRoute';
import Layout from './components/theme/Layout';
import 'bootstrap/dist/css/bootstrap.min.css';
import UseWindowSize from './services/UseWindowSize';
import Home from './views/brushing/Home';
import NaturalsDesktopLanding from './views/naturals/NaturalsDesktopLanding';
import DefaultPage from './views/DefaultPage';
import { useTranslation } from 'react-i18next';
// /import LotusRoute from './routes/LotusRoute';
//import Layout from  './components/theme/brushing/Layout';

function App() {
  const { t } = useTranslation('naturals');
  const size = UseWindowSize();
  const [prompt, setPrompt] = React.useState(null);
  const [addHomeScreenStatus, setAddHomeScreen] = React.useState('no');
  const [enableBackButton, setEnableBackButton] = React.useState('no');
  const [redirectPage, setRedirectPage] = React.useState('no');
  const updateCheckingData = (backButton,redirectValue) => {
      setEnableBackButton(backButton);
      setRedirectPage(redirectValue)
  };
  const promptToInstall = () => {
    if (prompt) {
      setAddHomeScreen('no');
      return prompt.prompt();
    }
    return Promise.reject(
      new Error('prompt not installed')
    );
  };
  React.useEffect(() => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setAddHomeScreen('yes');
    }
     window.addEventListener('beforeinstallprompt',e =>{
       // For older browsers
       e.preventDefault();
       //console.log('add');
       //console.log(e);
       setPrompt(e);
       setAddHomeScreen('yes');
     })
     return () => {
       window.removeEventListener('beforeinstallprompt',e =>{
         // For older browsers
        console.log('remove');
        console.log(e);
         e.preventDefault();
         setPrompt(e);
         setAddHomeScreen('yes');
       })
     };
   
   },[]);
   if (size.orientation === 'landscape') {
    return (
      <div className='screenMSG'>{t('portraitMsg.msg')}</div>
    );
  } 
  else if (size.width === undefined || size.width < 700) {
    return (
      <>
      <BrowserRouter >
      <Layout > 
             <LotusRoute redirectPage={redirectPage} deviceTemplate="mobile" updateCheckingData={updateCheckingData} promptToInstall={promptToInstall} addHomeScreenStatus={addHomeScreenStatus} enableBackButton={enableBackButton} />
      </Layout>
      </BrowserRouter>
        </>
    );
  } 
  return (
    <>
  <BrowserRouter>
  <Routes>
      <Route path="/" element={ <DefaultPage />} />
      <Route path="/mindful-brushing" element={ <Home layoutName="brushing" redirectPage={redirectPage} deviceTemplate="desktop" updateCheckingData={updateCheckingData} promptToInstall={promptToInstall} addHomeScreenStatus={addHomeScreenStatus} enableBackButton={enableBackButton}/>}>
        
      </Route>
      <Route path="/mindful-massage" element={<NaturalsDesktopLanding/>}></Route>
    </Routes>
  </BrowserRouter>
      </>
  );
  
}

export default App;
