import React from 'react';
//import SunIcon from '../assets/images/sunlight.svg';
//import MoonIcon from '../assets/images/light-blue-moon.svg';
//import AddIcon from '../assets/images/Addition.svg';
//import BlueAddIcon from '../assets/images/add-blue-icon.svg';
import DarkMoonIcon from '../assets/images/blue-dark-moon.svg';
import DarkSunIcon from '../assets/images/dark-sun-light.svg';
import { useTranslation } from 'react-i18next';
const PlayerSection = (propsValue) => {
  const { t } = useTranslation();
  const [currentSelectedSession, setCurrentSelectedSession] = React.useState(
    propsValue.currentSelectedSession
  );
  const [sessionType] = React.useState(propsValue.sessionType);
  const [divHeading, setDivHeading] = React.useState('');
  //const [divIcon, setDivIcon] = React.useState('');
  const [divIcon2, setDivIcon2] = React.useState('');
  const [divLayer, setDivLayer] = React.useState('');
  //const [addIcon, setAddIcon] = React.useState('');
  //const sessionType =propsValue.sessionType;
  const updateSessionType = (valueData) => {
    propsValue.updateSelectedValue(valueData);
  };
  const buttonClass = (keyValue) => {
    if (propsValue.allVideo[keyValue].status === 'inprocess') {
      return 'black-play-btn';
    } else if (propsValue.allVideo[keyValue].status === 'pending') {
      return 'play-btn';
    } else if (
      propsValue.allVideo[keyValue].status === 'complete' &&
      currentSelectedSession === 'AM'
    ) {
      return 'orange-tick-btn';
    } else if (
      propsValue.allVideo[keyValue].status === 'complete' &&
      currentSelectedSession === 'PM'
    ) {
      return 'purple-play-btn';
    }
  };

  React.useEffect(() => {
    setCurrentSelectedSession(propsValue.currentSelectedSession);
    if (sessionType === 'AM') {
      setDivHeading(t('selectsession.morningrelax'));
     // setDivIcon(SunIcon);
    //  setAddIcon(AddIcon);
      setDivIcon2(DarkSunIcon);
      setDivLayer('morning-color');
    }
   else if (sessionType === 'PM') {
      setDivHeading(t('selectsession.eveningrelax'));
     // setDivIcon(MoonIcon);
    //  setAddIcon(BlueAddIcon);
      setDivIcon2(DarkMoonIcon);
      setDivLayer('evening-color');
    }
  });
  return (
      <><div onClick={() => updateSessionType(sessionType)}
      className={currentSelectedSession === '' || currentSelectedSession !== sessionType
        ? 'd-flex card-layer ' + divLayer
        : 'hide-div'}>
      <div className="left-icon">
        <img src={divIcon2} alt="Moon"></img>
      </div>
      <div className="card-discriptions">{divHeading}</div>
       {/*<div className="right-icon" >
      <img src={addIcon} alt="Add"></img>
      </div>*/}
    </div><div
      className={currentSelectedSession === sessionType ? 'card-layer ' + divLayer : 'hide-div'}>
        <div className="d-flex">
          <div className="left-icon">
            <img src={divIcon2} alt="Moon"></img>
          </div>
          <div className="video-card-discriptions">{divHeading}</div>
        </div>
        <div className="video-section-width">
          <div className="sperator"></div>
          <div className="video-section">
            {Object.keys(propsValue.allVideo).map((key) => {
              return (
                <div
                  id={key}
                  key={key}
                  onClick={() => propsValue.sessionPlay(propsValue.allVideo[key], currentSelectedSession)}
                  className={buttonClass(key)}>
                  <p className="number">{propsValue.allVideo[key].keys}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="btn-center">
          <button
            type="button"
            onClick={() => propsValue.sessionPlay(propsValue.currentSessionData, currentSelectedSession)}
            className={currentSelectedSession === 'PM' ? 'btn btn-secondary' : 'btn btn-primary btnwidth50'}>
            Play session {propsValue.currentSessionData.short}
          </button>
        </div>
      </div></>
  );
};
export default PlayerSection;
