import React from 'react';
import { SetSessionView } from '../../services/Session';
import {pushInGoggleTag} from '../../services/DataLayerGa';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const OhImpact = (propsValue) => {
  const [show, setShow] = React.useState(true);
  const [selectedValue, setSelectedValue] = React.useState('');
  const closeWindowData = () => {
    setShow(false);
  };
  const updateFeedBack = (feedBackData) => {
    setSelectedValue(feedBackData);
    pushInGoggleTag({
      popupQuestion:t('ohlimpact.modelTitle'),
      'event': 'popup_feedback',
      popupFeedback:'popupFeedback',
      feedbackName:"ohImpact",
      feedbackValue:feedBackData
     },'V2');
    SetSessionView('ohImpactFeedback', feedBackData);
    propsValue.updateSelectedValue(propsValue.currentSelectedSession);
    setShow(false);
  };
  const { t } = useTranslation();
  return (
    <>
      <Modal className="brushingModel" show={show} onHide={closeWindowData}>
        <Modal.Header closeButton>
          <Modal.Title>{t('ohlimpact.modelTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="btn-section">
            <button
              className={
              selectedValue ==='Yes, likely'
                ? 'popup-feedback pop-button-ohimpact active'
                : 'popup-feedback pop-button-ohimpact'
              }
              shape="round"
              size="large"
              id="firstQ1"
              key="firstQ1"
              onClick={() => updateFeedBack('Yes, likely')}>
              {t('ohlimpact.button')}
            </button>
            <button
              shape="round"
              size="large"
              id="secondQ1"
              key="secondQ1"
              className={
                selectedValue ==='Maybe'
                  ? 'popup-feedback pop-button-ohimpact active'
                  : 'popup-feedback pop-button-ohimpact'
                }
              onClick={() => updateFeedBack('Maybe')}>
              {t('ohlimpact.button1')}
            </button>
            <button
              shape="round"
              size="large"
              id="thirdQ1"
              key="thirdQ1"
              className={
                selectedValue ==='Maybe'
                  ? 'popup-feedback pop-button-ohimpact active'
                  : 'popup-feedback pop-button-ohimpact'
                }
              onClick={() => updateFeedBack('No, not at all')}>
              {t('ohlimpact.button2')}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default OhImpact;
