import React  from 'react';
import headerback from '../../../assets/images/images-v3/right-arrow.svg';
import headerBlueback from '../../../assets/images/images-v3/blue-back-btn-blue.svg';
import { useSearchParams,  useNavigate} from 'react-router-dom';
import { MusicHeaderContent, GetThemeValue  } from '../../../services/Utils';
import { useTranslation } from 'react-i18next';
import { pageList  } from '../../../services/WebsiteUrl';

const PageHeadTitle = (propsValue) => {
  const navigate = useNavigate();
  const { t } = useTranslation('naturals');
  const [searchParams] = useSearchParams();
  const [pageListData] = React.useState(pageList());
  const pathname = window.location.pathname;
  const [headerTitleClass,setHeaderTitleClass] = React.useState('header-o');
  const [headerTitle,setHeaderTitle] = React.useState('');
  const [headerIcon,setHeaderIcon] = React.useState('');
  const [theme] = React.useState(GetThemeValue);
  const checkMenuIcon = ()=>{
    if(pathname === pageListData['v3Video'] )
    {
      navigate(pageListData['v3Session']);
    }
    else
    {
      propsValue.backButtonHandler();
    }
  }
  React.useEffect(() => {
     let videoId = searchParams.get('id');
     let returnData =  MusicHeaderContent(videoId);
    // alert(pathname + '----'+ pageListData['v3Video']);
    // if(theme === 'green')
    // {
       if(pathname === pageListData['v3Video'] )
       {
            setHeaderTitleClass(returnData.headerClass);
       } 
       else
       {
            setHeaderTitleClass('header-0');
       } 
       if(theme === 'green')
       {
       setHeaderTitle(returnData.headerTranslationGreen);
       setHeaderIcon(returnData.headerImage);
       }
       else
       {
        setHeaderTitle(returnData.headerTranslationBlue);
       }
       
       
    /* }
     else
     {
       setHeaderTitleClass('header-o');
       setHeaderTitle(returnData.headerTranslationBlue);
       setHeaderIcon(returnData.headerImage);
     }*/
   });
  return (
    <><div className={'thearapy-header '+ headerTitleClass}>
      <div className="backup-btn"  onClick={checkMenuIcon}>
      <img src={theme==='green'?headerback:headerBlueback} alt="back"></img>
      </div>
      <div className="thearapy-container">
      {theme==='green'?(  <div className="thearapy-icon"><img src={headerIcon} alt={t(headerTitle)}></img></div>):('')}
        <div className="thearapy-heading">{t(headerTitle)}</div>
      </div>

    </div>
    </>
  );
};
export default PageHeadTitle;
