import React from 'react';
import profileImage from '../../assets/images/excutive-pic.png';
import { useTranslation } from 'react-i18next';

const AboutExpert = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
  });
  return (
    <>
      <div className="mobile-view-container">
      <div className="curve1"></div>
        <div className="static-pages">
          <div className="profile-img">
            <img src={profileImage} alt={profileImage} />
          </div>
          <div className="profile">
            <span>{t('about.heading')}</span>
            {t('about.heading1')}
          </div>
          <div>
            <h2>{t('about.text_heading')}</h2>
            <p> {t('about.ptext')}</p>
            <p>{t('about.ptext1')}</p>
            <p>{t('about.ptext2')}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutExpert;
