import React from 'react';

import { SetLocalStorage } from '../services/Session';
import clientLogo from '../assets/images/clametine-logo.png';
import excutivePic from '../assets/images/excutive-pic.png';
import { useTranslation } from 'react-i18next';
import {pushInGoggleTag} from '../services/DataLayerGa';
const VideoSession = (sessionProp) => {
  const { t } = useTranslation();
  const videoRef = React.useRef();
  const [sessionHeading, setSessionHeading] = React.useState('');
  const [sessionTitle, setSessionTitle] = React.useState('');
  const [videoSrc, setVideoSrc] = React.useState('../assets/video/' +
  sessionProp.videoType +
  '/' +
  sessionProp.sessionObject.short +
  '.mp4');
  const [posterSrc, setPosterSrc] = React.useState(sessionProp.videoType +'/' +
  sessionProp.sessionObject.short + '.mp4');
  const [subtitle, setSubtitle] = React.useState('OFF');
  const changeSubtitle = () => {
    if (subtitle === 'ON') {
      setSubtitle('OFF');
      setVideoSrc('../assets/video/' +
      sessionProp.videoType +
      '/' +
      sessionProp.sessionObject.short +
      '.mp4');
      setPosterSrc(sessionProp.videoType +  sessionProp.sessionObject.short );
    } else {
      setVideoSrc('../assets/video/' +
      sessionProp.videoType +
      '/' + '/subtitlevideo/' +
      sessionProp.sessionObject.short +
      '.mp4');
      setSubtitle('ON');
      setPosterSrc(sessionProp.videoType + sessionProp.sessionObject.short );
    }
    videoRef.current.load();
  };

  const onPlay = () => {
    pushInGoggleTag({'event': 'video-status'},'V2');
    
  }
  const endVideoCallback = () => {
    let video = document.getElementById('videokey');
    let vdur = video.duration;
    let vdurCurrentTime = video.currentTime;
    let storageData = {
      video_total_duration: vdur,
      video_currentTime: vdurCurrentTime,
      complete_video: sessionProp.sessionObject.short
    };
    SetLocalStorage('videoSession', JSON.stringify(storageData));
    let feedBackPending = {};
    feedBackPending[sessionProp.videoType] = sessionProp.sessionObject.short;
    SetLocalStorage('feedbackPending', JSON.stringify(feedBackPending));
    sessionProp.enableFeedback(sessionProp.videoType,sessionProp.sessionObject.short);
  };
  React.useEffect(() => {
    if (sessionProp.videoType === 'AM') {
      setSessionHeading(t('videopage.'+sessionProp.sessionObject.short+'.mrgHeading'));
      setSessionTitle(t('videopage.'+sessionProp.sessionObject.short+'.mrgTitle'));
    } else {
      setSessionHeading(t('videopage.'+sessionProp.sessionObject.short+'.evgHeading'));
      setSessionTitle(t('videopage.'+sessionProp.sessionObject.short+'.evgTitle'));
    }
  },[sessionProp.videoType, t]);

  return (
    <>
      <h2>
        {sessionHeading}
      </h2>
      <p className="heading-text">
        <span>{t('videopage.video_size')} </span>
       {sessionTitle}
      </p>
      <div className={sessionProp.videoType === 'PM' ? 'evening-range' : 'morning-range'}>
        <video
          poster={posterSrc}
          ref={videoRef}
          className="video-size"
          controlsList="nodownload"
          id="videokey"
          width="100%"
          height="auto"
          autoPlay={true}
          controls="controls"
          playsInline
          onPlay={() => onPlay()}
          onEnded={() => endVideoCallback()}>
          <source
            src={videoSrc}
            type="video/mp4"
          />
        </video>
        <div className="video-subtitle" id="video-subtitle"></div>
      </div>{' '}
      <div id="videocontent"></div>
      <div className="tb-3">
        <div className="switch-btn">
          <span className="subtitle-label">{t("videopage.subtitles")}</span>
          <label className="switch">
            <input
              type="checkbox"
              onChange={() => changeSubtitle()}
              checked={subtitle === 'ON' ? 'checked' : ''}
            />
          <span className={sessionProp.videoType == 'PM' ? 'slider-blue slider round' : 'slider-peach  slider round'} ></span>
            <span className={subtitle === 'ON' ? 'toggle-on' : 'toggle-off'}>{subtitle==='ON' ? t('videopage.off') : t('videopage.on') }</span>
          </label>
        </div>
        <div id="video-subtitle"></div>
      </div>
      <div className="tb-3">
        <div className="morning-card">
          <div className="ex-details">
            <div className="client-logo">
              <img src={clientLogo} alt="Clementine Logo"></img>
              <div className="name">Emily Hodge</div>
              <div className="designation">Mind Coach (MBPsS)</div>
            </div>

            <div className="ex-pic">
              <img src={excutivePic} alt="Professional headshot of Emily Hodge smiling "></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoSession;
