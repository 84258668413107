import React from 'react';
import RatingSlider from '../../components/RatingSlider';
import { GetLocalStorage, GetVoltaraNaturalFeedback, SetVoltaNaturaSession, RemoveVoltaraNaturalFeedback } from '../../services/Session';
import { useNavigate } from 'react-router-dom';
import { defualtPage } from '../../services/WebsiteUrl';
import {pushInGoggleTag} from '../../services/DataLayerGa';

const NaturalsSlider = () => {
  const navigate = useNavigate();
  const [videoName,setVideoName] = React.useState('');
  const [emotions,setEmotions] = React.useState('');
  const [otheremotions,setOtherEmotionData] = React.useState('');
  React.useEffect(() => {
    let feedBackData = GetVoltaraNaturalFeedback();
    if(feedBackData != null && feedBackData != undefined  )
    { 
      let pendingData = JSON.parse(feedBackData);
      setVideoName(pendingData.pendingVideo);
      setEmotions(pendingData.emotions);
      setOtherEmotionData(pendingData.otheremotions);
    }
   
  },[]);
  let returnData = defualtPage('v3Session');
  const finalFeedBack = (rating) => {
       SetVoltaNaturaSession('video', {
          rating: rating,
          emotions: emotions,
          otheremotions: otheremotions,
          subtitles: 'on'
        },videoName,'videoFeedback');
      pushInGoggleTag({
        'event': 'feedback_capture',
        videoFeedBackName:videoName,
        feedbackProvider:"videoFeeback",
        videoFeedback:emotions,
        subTitle:GetLocalStorage('subtitle'),
        videoRating:rating
        },'V3');
    RemoveVoltaraNaturalFeedback();
    navigate(returnData);

  }
  
  if(videoName !== '' )
{
  return (
    <>
    <div className="emotion-bg"><RatingSlider finalFeedBack={finalFeedBack}/>
    </div>
    </>
  );
}
};

export default NaturalsSlider;
