const websiteUrl = {
    'v2Landing':"/mindful-brushing",
    "v2Hyponotheraphy":"/mindful-brushing/Hypnotherapy",
    "v2HowItWorks":"/mindful-brushing/Howitworks",
    "v2Intro":"/mindful-brushing/WalkThrough",
    "v2Expert":"/mindful-brushing/AboutExpert",
    "v2TermCondition":"/mindful-brushing/TermCondition",
    "v2Session":"/mindful-brushing/PlaySession",
    'v3Landing':"/mindful-massage",
    "v3HowItWorks":"/mindful-massage/volta-works",
    "v3Intro":"/mindful-massage/volta-intro",
    "v3Expert":"/mindful-massage/volta-expert",
    "v3TermCondition":"/mindful-massage/volta-termuse",
    "v3Session":"/mindful-massage/volta-home",
    "v3Video":"/mindful-massage/volta-theraphy",
    "v3Emotions":"/mindful-massage/volta-reflection",
    "v3Slider":"/mindful-massage/volta-slider"
}

const backButtonArray = {'/mindful-brushing/Hypnotherapy':{headerClass:'header-mobileheight',backButton:'yes',redirect:'history','theme':'curvedesign-bg-color-white',lotusVersion:'v2'},
'/mindful-brushing/WalkThrough':{headerClass:'header-mobileheight',backButton:'no',redirect:'no','theme':'curvedesign-bg-color-white',lotusVersion:'v2'},
'/mindful-brushing/Howitworks':{headerClass:'header-mobileheight',backButton:'yes',redirect:'history','theme':'curvedesign-bg-color-white',lotusVersion:'v2'},
'/mindful-brushing/TermCondition':{headerClass:'header-mobileheight',backButton:'yes',redirect:'history','theme':'curvedesign-bg-color-white',lotusVersion:'v2'},
'/mindful-brushing/AboutExpert':{headerClass:'header-mobileheight',backButton:'yes',redirect:'history','theme':'curvedesign-bg-color-white',lotusVersion:'v2'},
'/mindful-brushing':{headerClass:'',backButton:'no',redirect:'no','theme':'',lotusVersion:'v2'},
'/mindful-brushing/PlaySession':{headerClass:'header-mobileheight',backButton:'no',redirect:'same','theme':'curvedesign-bg-color-white',lotusVersion:'v2'
},
'/mindful-massage':{headerEnabled:true,headerClass:'landing',backButton:'no',redirect:'same','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-works':{headerEnabled:true,headerClass:'',backButton:'yes',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-expert':{headerEnabled:true,headerClass:'',backButton:'yes',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-termuse':{headerEnabled:true,headerClass:'',backButton:'yes',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-intro':{headerEnabled:true,headerClass:'',backButton:'no',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-home':{headerEnabled:true,headerClass:'',backButton:'no',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/single-home':{headerEnabled:true,headerClass:'',backButton:'no',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-theraphy':{headerEnabled:false,headerClass:'',backButton:'no',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-theraphy1':{headerEnabled:false,headerClass:'',backButton:'no',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-reflection':{headerEnabled:false,headerClass:'',backButton:'no',redirect:'history','theme':'',lotusVersion:'v3'},
'/mindful-massage/volta-slider':{headerEnabled:false,headerClass:'',backButton:'no',redirect:'history','theme':'',lotusVersion:'v3'}
};

export const defualtPage=(keyName)=>{
    if (websiteUrl[keyName] !== undefined ) { 
     return websiteUrl[keyName]
    }
    else
    {
        return "/mindful-brushing";
    }

};

export const pageList=()=>{
  
return websiteUrl;
};

export const backButtonData = ()=>{
     
return backButtonArray; 
}