import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import MobileHome from '../views/brushing/MobileHome';
import SelectSession from '../views/brushing/SelectSession';
import AboutExpert from '../views/brushing/AboutExpert';
import TermCondition from '../views/brushing/TermCondition';
import Walkthrough from '../views/brushing/Walkthrough';
import NaturalsHome from '../views/naturals/NaturalsHome';
import NaturalsLanding from '../views/naturals/NaturalsLanding';
import NaturalsIntro from '../views/naturals/NaturalsIntro';
import NaturalsExpert from '../views/naturals/NaturalsExpert';
//import NaturalsHeader from '../components/theme/naturals/Header';
import { GetThemeValue,  SetThemeValue  } from '../services/Utils';
import NaturalsTermUse from '../views/naturals/NaturalsTermUse';
import NaturalsTheraphy from '../views/naturals/NaturalsTheraphy';
import NaturalsReflection from '../views/naturals/NaturalsReflection';
import NaturalsSlider from '../views/naturals/NaturalsSlider';
import { pageList } from '../services/WebsiteUrl';
//import DefaultPage from '../views/DefaultPage';
import { useSearchParams } from 'react-router-dom';
const LotusRoute = (propsValue) => {
  const [pageListData] = React.useState(pageList()); 
    const [searchParams] = useSearchParams();
    const [themeName, setThemeName] = React.useState('');
    React.useEffect(() => {
       let themeValue = searchParams.get('theme');
       SetThemeValue(themeValue);
       setThemeName(GetThemeValue());
     
      });
   return (<>
      <Routes>  
      <Route path="/" element={<Navigate to="/mindful-brushing" />} />
         <Route
              path="/mindful-brushing"
              element={<MobileHome layoutName="brushing"  addHomeScreenStatus={propsValue.addHomeScreenStatus}
              promptToInstall={propsValue.promptToInstall} updateCheckingData={propsValue.updateCheckingData} />}
            />
            
            <Route
              path="/mindful-brushing/Hypnotherapy"
              element={
                <Walkthrough  layoutName="brushing"/>
              }
            />
            <Route
              path="/mindful-brushing/Howitworks"
              element={
                <Walkthrough  layoutName="brushing"
                />
              }
            />
             <Route
              path="/mindful-brushing/WalkThrough"
              element={
                <Walkthrough layoutName="brushing"/>
              }
            />
            <Route
              path="/mindful-brushing/PlaySession"
              element={
                <SelectSession  layoutName="brushing" addHomeScreenStatus={propsValue.addHomeScreenStatus}
                promptToInstall={propsValue.promptToInstall} updateCheckingData={propsValue.updateCheckingData} 
                />
              }
            />
            <Route
              path="/mindful-brushing/AboutExpert"
              element={
                <AboutExpert layoutName="brushing"
                />
              }
            />
            <Route
              path="/mindful-brushing/TermCondition"
              element={
                <TermCondition layoutName="brushing"
                />
              }
            /> 
             <Route
               exact path={pageListData['v3Landing']}
                element={<NaturalsLanding  theme={themeName}  layoutName="voltanatural"/>}
              />
               <Route
                path={pageListData['v3TermCondition']}
                element={<NaturalsTermUse  layoutName="voltanatural"/>}
              />
              <Route
                path={pageListData['v3Session']}
                element={<NaturalsHome   layoutName="voltanatural"  addHomeScreenStatus={propsValue.addHomeScreenStatus}
                  promptToInstall={propsValue.promptToInstall}/>}
              />
              
             <Route
              pageData='intro'
                path={pageListData['v3Intro']} element={<NaturalsIntro  layoutName="voltanatural" />}
              />
               <Route
                path={pageListData['v3Expert']}
                element={<NaturalsExpert  layoutName="voltanatural"/>}
              />
               <Route
                path={pageListData['v3HowItWorks']}
                element={<NaturalsIntro  layoutName="voltanatural"/>}
              />
              
              <Route
                path={pageListData['v3Video']}
                element={<NaturalsTheraphy  layoutName="voltanatural"/>}
              />
              
                 <Route
                 key="emotions"
                path={pageListData['v3Emotions']}
                element={<NaturalsReflection  layoutName="voltanatural"/>}
              />
              <Route
              key="slider"
                path={pageListData['v3Slider']}
                element={<NaturalsSlider  layoutName="voltanatural"/>}
              />
          </Routes> </>
    );
  } 

export default LotusRoute;
