import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/traslations.json'),
     naturals:require('./locales/en/naturals.json'),
    },
    es: {
      translations: require('./locales/es/traslations.json')
    }
  },
  ns: ['translations','naturals'],
  defaultNS: 'translations',
  interpolation: { escapeValue: false }
});

i18n.languages = ['en', 'es'];

export default i18n;
