import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const Emotions = (propsValue) => {
  const { t } = useTranslation();
  const [buttonClass, setButtonClass] = useState('');
  const [buttonError, setButtonError] = useState('');
  const [emotionsFeedBackList, setEmotionsFeedBackList] = useState([]);
  const emotionList1 = [
    'Happy',
    'Calm',
    'Relaxed',
    'Sad',
    'Stressed',
    'Tense',
    '+'
  ];
  React.useEffect(() => {
    setButtonClass('evnbtn');
  });


  const updateEmotions = (emotionValue) => {
    let emotionsFeedBackListData = [...emotionsFeedBackList];
     let index = emotionsFeedBackListData.indexOf(emotionValue);
     if (index !== -1) {
       emotionsFeedBackListData.splice(index, 1);
       setEmotionsFeedBackList(emotionsFeedBackListData);
     } else {
       emotionsFeedBackListData.push(emotionValue);
       setEmotionsFeedBackList(emotionsFeedBackListData);
     }
   
   };
   const finalFeedBack = () => {
    let validError = validationCheck();
    if (validError === false) {
        propsValue.finalFeedBack(emotionsFeedBackList,'');
      
    }
   
  };
   const validationCheck = () => {
    let validStatus = false;

    if (emotionsFeedBackList.length === 0) {
      validStatus = true;
      setButtonError(t('feedback.emotionErr'));
    } 
    return validStatus;
  };
  return (
    <>
    
      <div className="emotion-section">
      <p>Take a moment to reflect on how you feel now.</p>
        {(() => {
          const arr = [];
          for (let i = 0; i < 6; i++) {
          
              arr.push(
                <button
                  type="button"
                  key={emotionList1[i]}
                  id={emotionList1[i]}
                  className={
                    emotionsFeedBackList.indexOf(emotionList1[i]) !== -1
                      ? buttonClass + ' feedback-capture active'
                      : buttonClass + ' feedback-capture '
                  }
                  onClick={() => updateEmotions(emotionList1[i])}>
                  {emotionList1[i]}
                </button>
              );
            
          }
          return arr;
        })()}
       
      <span className="text-color-danger">{buttonError}</span>
      </div>
     
      <div className="btn-center">
        <button
          type="button"
          className="emotion-btn " onClick={() => finalFeedBack()}>
          {t('button.submit')}
        </button>
      </div>
  
    </>
  );
};
export default Emotions;
