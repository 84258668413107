import React from 'react';
import { SetSessionView, SetVoltaNaturaSession } from '../../services/Session';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import {pushInGoggleTag} from '../../services/DataLayerGa';
import { pageList } from '../../services/WebsiteUrl';
/*this component is used for 
getting personal Info of user
*/
const EmailCapture = (propsValue) => {
  const { t } = useTranslation();
  const [pageListData] = React.useState(pageList());
  const [show, setShow] = React.useState(true);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [privacy, setPrivacy] = React.useState(false);
  const [firstNameError, setFirstNameError] = React.useState('');
  const [emailError, setEmailError] = React.useState('');
  const [privacyError, setPrivacyError] = React.useState('');
  const [emailPopupClass,setEmailPopupClass] = React.useState(propsValue.emailPopupClass);
  const [totalCompletedVideo, setTotalCompletedVideo] = React.useState(
    propsValue.totalCompletedVideo
  );
  /**
   * call when privacy checkbox is clicked
   * @param {*} e 
   */
  
  const onChange = (e) => {
    setPrivacy(e.target.checked);
  };
   /**
    *  if validation failed in field then error message is 
    set by this function for particular field
    * @param {*} errorValue 
    * @param {*} fieldName 
    */

  function setError(errorValue, fieldName) {
    if (fieldName === 'email') {
      setEmailError(errorValue);
    } else if (fieldName === 'firstname') {
      setFirstNameError(errorValue);
    } else if (fieldName === 'privacy') {
      setPrivacyError(errorValue);
    }
  }

  /**
   * function validated field values
   * @returns status in true and false, error message in object
   */
 
  function validateData() {
    let formIsValid = true;
    let errors = {};
    if (privacy === false) {
      formIsValid = false;
      errors['privacy'] = 'Please click check box';
      setError(errors['privacy'], 'privacy');
    } else {
      setError('', 'privacy');
    }
    if (firstName === '' || firstName == null || firstName === undefined) {
      formIsValid = false;
      errors['firstName'] = t('emailCapture.name_empty_error');
      setError(errors['firstName'], 'firstname');
    } else if (typeof firstName !== 'undefined') {
      if (!firstName.match(/^[a-zA-Z ]+$/)) {
        formIsValid = false;
        errors['firstName'] = t('emailCapture.name_valid_error');
        setError(errors['firstName'], 'firstname');
      } else {
        setError('', 'firstname');
      }
    }
    if (email === '' || email == null || email === undefined) {
      formIsValid = false;
      errors['email'] = t('emailCapture.email_empty_error');
      setError(errors['email'], 'email');
    } else if (typeof email !== 'undefined') {
      let lastAtPos = email.lastIndexOf('@');
      let lastDotPos = email.lastIndexOf('.');

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          email.indexOf('@@') === -1 &&
          lastDotPos > 2 &&
          email.length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors['email'] = t('emailCapture.email_valid_error');
        setError(errors['email'], 'email');
      } else {
        setError('', 'email');
      }
    }

    return { formIsValid: formIsValid, errorsData: errors };
  }
/**
 * used to update user infor in localStorage
 */

  const updateProfile = () => {
    let checkValidation = validateData();
    if (checkValidation.formIsValid === true) {
      let storageData = {
        firstName: firstName,
        lastName: lastName,
        email: email
      };
      if( emailPopupClass === 'brushingModel'  )
      {
          SetSessionView('profile', storageData);
          pushInGoggleTag({
            'event': 'email_capture',
          },'V2');
      }
      else
      {
        SetVoltaNaturaSession('profile',storageData,'','email_capture');
        pushInGoggleTag({
          'event': 'email_capture',
        },'V3');
      }
    
      propsValue.enablePopup('profileThanksView');
    } else {
      return false;
    }
  };

  /**
   * used for empty the error message when value in input field is changed
   * @param {*} fieldName 
   * @param {*} e 
   */
  function handleChange(fieldName, e) {
    e.preventDefault();
    if (fieldName === 'email') {
      setEmailError('');
      setEmail(e.target.value);
    } else if (fieldName === 'firstname') {
      setFirstName(e.target.value);
      setFirstNameError('');
    }
  }

  /**
   * check for close action taken by user in which condition
   * @param {*} valueData 
   */
  function dontAskMe(valueData) {
    let keyName = '';
    if (valueData === 'close' && totalCompletedVideo === 1) {
      keyName = 'dontAskMeVideo2Completed';
    } else {
      keyName = 'dontAskMe';
    }
    if( emailPopupClass === 'brushingModel'  )
    {
        SetSessionView(keyName, 'yes');
    }
    else
    {
      SetVoltaNaturaSession(keyName,'yes','','noupdate');
    }
    setShow(false);
  }
  /**
   * call when component is loaded
   */
  React.useEffect(() => {
    
    setTotalCompletedVideo(propsValue.totalCompletedVideo);
    setEmailPopupClass(propsValue.emailPopupClass);
    let profileValue = JSON.parse(localStorage.getItem('profile'));
    if (profileValue != null && profileValue == undefined) {
      setEmail(profileValue.email);
      setFirstName(profileValue.firstname);
      setLastName(profileValue.lastname);
    }
  });
  return (
    <>
      <Modal show={show} className={emailPopupClass} onHide={() => dontAskMe('close')}>
        <Modal.Header closeButton>
          <Modal.Title>{t('emailCapture.modelTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
          { emailPopupClass === 'brushingModel' ? t('emailCapture.iconText') : t('emailCapture.iconVoltaText') }
          </p>
          <form>
            <div className="form-group">
              <label>{t('emailCapture.firstname')}</label>
              <input
                type="Text"
                className="form-control"
                value={firstName}
                onChange={(e) => handleChange('firstname', e)}></input>
              <span className="text-color-danger">{firstNameError}</span>
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="Text"
                className="form-control"
                value={email}
                onChange={(e) => handleChange('email', e)}></input>
              <span className="text-color-danger"> {emailError}</span>
            </div>

            <div className="form-check">
              <label className="form-check-label">
                { emailPopupClass === 'brushingModel' ? t('emailCapture.message') : t('emailCapture.constent2') }{' '}
                <a href="https://www.privacy.haleon.com/en-gb/" target="_blank" rel="noreferrer">
                  {' '}
                  {t('emailCapture.privacymessage')}
                </a>{' '}
                and{' '}
                {emailPopupClass === 'brushingModel' ?
                (<Link to={pageListData['v2TermCondition']}target="_blank">
                  {t('emailCapture.tcurl')}
                </Link>):
                (<Link to={pageListData['v3TermCondition']}target="_blank">
                  {t('emailCapture.tcurl')}
                </Link>)}
              </label>
              <input type="checkbox" className="form-check-input" onChange={onChange}></input>
              <span className="text-color-danger"> {privacyError}</span>
            </div>
            <div className="btn-center">
              <button
                type="button"
                className={emailPopupClass === 'brushingModel' ? 'btn btn-primary mar-btn email-capture':'pop-btn'}
                onClick={() => updateProfile()}>
                Submit
              </button>
            </div>
            <div className="btn-center" onClick={() => dontAskMe('dontAskMe')}>
              <a href="#">{t('emailCapture.textButton')}</a>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default EmailCapture;
