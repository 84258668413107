import React from 'react';
import './css/App.scss';
import MenuSidebar from '../MenuSidebar';
//import logo from '../../../../assets/images/VoltarolNaturals6.png';
import logo from '../../../../assets/images/images-v3/VoltarolNaturals-logo1.png';
import innerLogo from '../../../../assets/images/images-v3/VoltarolNaturals-logo1.png';
const HeaderGreen = (propsValue) => {
  const [sidebar, setSidebar] = React.useState(false);
  const [menuClass,setMenuClass] = React.useState('');
  const [headerClassName,setHeaderClassName] =React.useState('');
  const checkMenuIcon = ()=>{
    if(propsValue.backButton === 'yes')
    {
      propsValue.backButtonHandler();
    }
    else
    {
      showSidebar();
    }
  }
  const showSidebar = () => 
  {
    if(sidebar === false)
    {
      setMenuClass('top-menu change');
    }
    else
    {
      setMenuClass('top-menu');
    }
    setSidebar(!sidebar);
   
  }
  React.useEffect(() => {
    if(propsValue.backButton === 'yes')
    {
      setMenuClass('back-button');
    }
    else if(sidebar === false)
    {
      setMenuClass('top-menu');
    }

    if(propsValue.deviceType === 'desktop')
    {
      setHeaderClassName('desktop-header d-flex justify-content-center');
    }
    else
    {
      if(propsValue.headerClass === 'landing')
      {
        setHeaderClassName('header d-flex justify-content-center');
      }
      else
      {
        setHeaderClassName('header d-flex');
      }
    }
   });
  return (
    <>
        <div className={headerClassName}>   
        <div className={propsValue.headerClass=='landing' ? 'hide-div' :menuClass } onClick={checkMenuIcon}>
        <div className="bar1"></div>
         <div className="bar2"></div>
        </div>         
       {<img src={propsValue.headerClass=='landing' ? logo : innerLogo} alt="Logo"></img>}
       {/*<img src={logo} alt="Logo"></img>*/}
        </div>
        
        <MenuSidebar showSidebar={showSidebar} sidebar={sidebar}/>
    </>
  );
};
export default HeaderGreen;
