import React ,{useState} from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GetThemeValue  } from '../../../services/Utils';
import { SetLocalStorage } from '../../../services/Session';
import blueTubegel from '../../../assets/images/images-v3/add-short-cut.svg';
import blueqrCodeImg from '../../../assets/images/images-v3/qr-code-blue-theme.svg';
import greenTubegel from '../../../assets/images/images-v3/gel-tube.png';
import greenQrCodeImg from '../../../assets/images/images-v3/qr-code-gel-tube.png';
import tapShare from '../../../assets/images/images-v3/tap-share-icon.svg';
import homeShare from '../../../assets/images/images-v3/tap-share-plus.svg';
import tapShareblue from '../../../assets/images/images-v3/add-home-icon.svg';
import homeShareblue from '../../../assets/images/images-v3/tap-share-blue-icon.svg';

const EntryPopup = (propsValue) => {
  const [theme] = React.useState(GetThemeValue());
  const [show, setShow] = React.useState(true);
  const [popupClass,setPopupClass] = React.useState('');
  const [isAppIos, setIsAppIos] = useState(false);
  const [contentData, setContentData] = useState('false');
  const [imageName, setimageName] = useState('false');
  const [imgAlt, setImgAlt] = useState('false');
  const { t } = useTranslation('naturals');
  function changeModel() {
    SetLocalStorage('appIcon', 'yes');
    setShow(false);
  }
 
  function addShortcut(){
//  alert('entrypoup');
  propsValue.promptToInstall();
    SetLocalStorage('appIcon', 'yes');
    setShow(false);
  }
  React.useEffect(() => {
    setPopupClass('volta-model-' + GetThemeValue());

   if (propsValue.popTemplate === 'appIconView') {
    setContentData(t('entryPopup.iconText'));
    if(theme === 'blue')
    {
      setimageName(blueTubegel);
    }
    else
    {
      setimageName(greenTubegel);
    }
    setImgAlt(t('entryPopup.emailAlt'));
    
  } else {
    setContentData(t('entryPopup.qrCodeText'));
    
    if(theme === 'blue')
    {
      setimageName(blueqrCodeImg);
    }
    else
    {
      setimageName(greenQrCodeImg);
    }
    setImgAlt(t('entryPopup.qrCodeAlt'));
  }
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Checks if should display install popup notification:
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    setIsAppIos(true);
     }
  // if are standalone android OR safari
  if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
    // hidden the button
    changeModel();
  }
  // do action when finished install
  window.addEventListener('appinstalled', e => {
    e.preventDefault();
    changeModel();
    //console.log("success app install!");
  });
  });


  return (
    <>
      <Modal show={show} className={popupClass}  onHide={changeModel}>
        <div className="modal-content-green">
        <div className="body-content-green">
      <Modal.Header closeButton>
          <Modal.Title><div className="addhometitle">{contentData}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
      
       
        <img src={imageName} alt={imgAlt}></img>
        {/* <img src={addshortcut} alt="Add short cut"></img> */}
         </Modal.Body>
          </div>
          {isAppIos ? (
   <div className="addhome" >
   <div className="tapShare"><div className="add-heading">{t('entryPopup.browser')}</div></div>
   <div className="tapShare">
    <img src={theme== 'blue'? homeShareblue: tapShare} /> <p>{t('entryPopup.iosText')}</p>
   </div>
   <div className="tapShare">                
     <img src={theme== 'blue'? tapShareblue: homeShare}/> <p>{t('entryPopup.iosText2')} </p>
   </div>
 </div>
):(
<button className="pop-btn btnwidth95" onClick={() => addShortcut()}> {t('entryPopup.button')} </button>)}
          </div>
         
      </Modal>
    </>
  );
};
export default EntryPopup;