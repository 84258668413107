import React from 'react';
import QrImg from '../../assets/images/qrcode_uat.png';
import cardImg1 from '../../assets/images/morning-image.svg';
import cardImg2 from '../../assets/images/evening-illustration.svg';
import cardImg3 from '../../assets/images/BrushImageLotusV2.png';
import { useTranslation } from 'react-i18next';
import Navbar from '../../components/theme/brushing/Navbar';
const Home = (propsValue) => {
  const { t } = useTranslation();
  const [deviceTemplate, setdeviceTemplate] = React.useState(propsValue.deviceTemplate);
  const [enableBackButton, setEnableBackButton] = React.useState(propsValue.enableBackButton);
  const [redirectPage, setRedirectPage] = React.useState(propsValue.redirectPage);
    React.useEffect(() => {
       setdeviceTemplate(propsValue.deviceTemplate);
       setEnableBackButton(propsValue.enableBackButton);
       setRedirectPage(propsValue.redirectPage);
      });
  return (
    <>
     <div className="mobile-view">
      <Navbar redirectPage={redirectPage}
      deviceTemplate={deviceTemplate} enableBackButton={enableBackButton} updateCheckingData={propsValue.updateCheckingData} />
      <div className="mid-content curvedesign-bg-color-landing">
      <div className="curve1"></div>
        <div className="home-center">
          <h1>{t('desktopLanding.text1')}</h1>
          <div className="get pd-tb"> {t('desktopLanding.text2')}</div>
          <div className="qr">{t('desktopLanding.text3')}</div>
          <div className="qr-img">
            <img src={QrImg} alt="site qrcode"></img>
          </div>
        </div>
        <div className="card-slider">
          <div className="card">
            <div className="card-heading">{t('desktopLanding.text4')}</div>
            <div className="card-descriptions">
            {t('desktopLanding.text5')}<p></p>
            <p><span>  {t('desktopLanding.text6')}</span></p>
            </div>
            <div className="card-img">
              <img className="card-img-size1" src={cardImg3} alt="7 topics to grow stronger each time you brush"></img>
            </div>
          </div>
          <div className="card">
            <div className="card-heading">{t('desktopLanding.text7')}</div>
            <div className="card-descriptions">
            {t('desktopLanding.text8')} 
            </div>
            <div className="card-img">
              <img className="card-img-size2" src={cardImg1} alt="Add a positive start to every morning"></img>
            </div>
          </div>
          <div className="card">
            <div className="card-heading"> {t('desktopLanding.text9')} </div>
            <div className="card-descriptions"> {t('desktopLanding.text10')} 
           
            </div>
            <div className="card-img">
              <img src={cardImg2} alt="Time to brush" className="card-img-size"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default Home;
