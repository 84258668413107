import React from 'react';
import Image from '../../assets/images/tooth brush.svg';
import { GetCurrentVideo, RemoveFeedBack } from '../../services/Session';
import PlayerSection from '../../components/PlayerSection';
import VideoSession from '../../components/VideoSession';
import VideoFeeback from '../../components/VideoFeedback';
import EamilCapture from '../../components/popups/EmailCapture';
import FinishedThank from '../../components/popups/FinishedThank';
import BrandAffinityFeedBack from '../../components/popups/BrandAffinityFeedBack';
import OhImpact from '../../components/popups/OhImpact';
import OhImprovement from '../../components/popups/OhImprovement';
import FinishedFeedback from '../../components/popups/FinishedFeedback';
import AppIcon from '../../components/popups/AppIcon';
//import {useSearchParams } from 'react-router-dom';

import PropTypes from 'prop-types';

class SelectSession extends React.Component {
  static propTypes = {
    enableBackButton: PropTypes.string,
    updateCheckingData: PropTypes.func,
    promptToInstall: PropTypes.func,
    addHomeScreenStatus:PropTypes.string

  };
  constructor(props) {
    super(props);
    this.state = {
      appIconViewStatus: 'yes',
      defualtHeading: 'Welcome!',
      currentSelectedSession: '',
      allVideo: {},
      currentSessionData: { short: '' },
      currentSessionDataValue: {},
      completedVideo: {},
      pendingFeedBack: 'no',
      viewTemplate: 'sessionView',
      videoKey: '',
      subtitle: 'off',
      popTemplate: '',
      videoSession: {},
      appIconView:''
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const queryParamValue = params.get('videoType');
    if( queryParamValue != undefined && queryParamValue != null )
    {
        let videoData = queryParamValue.split('_');
        if( videoData.length == 2 )
        {
        this.setState({currentSelectedSession:videoData[0]});
        let valueData= videoData[0];
        RemoveFeedBack(valueData);
        let currentSessionDataValue = GetCurrentVideo(valueData);
        currentSessionDataValue.viewVideo = 'yes';
        currentSessionDataValue.currentVideo = currentSessionDataValue.allVideo[videoData[1]];
        this.setState({ currentSessionDataValue: currentSessionDataValue });
        this.setState({ allVideo: currentSessionDataValue.allVideo });
        this.setState({ completedVideo: currentSessionDataValue.completedVideo });
        this.sessionPlay( currentSessionDataValue.currentVideo,videoData[0]);
        }
        else if(videoData.length == 1  )
        {
          let valueData= videoData[0];
          this.updateSelectedValue(valueData);
        }
    }
    else
    {
      this.setState({ viewTemplate: 'sessionView' });
      this.props.updateCheckingData('','');
    }
    this.setState({prompt:this.props.prompt});
    this.addHomeScreen();
  }

updatePageData = (valueData) =>{
  let currentSessionDataValue = GetCurrentVideo(valueData);
  this.setState({ currentSessionDataValue: currentSessionDataValue });
  this.setState({ allVideo: currentSessionDataValue.allVideo });
  this.setState({ completedVideo: currentSessionDataValue.completedVideo });
  if (currentSessionDataValue.currentVideo == null) {
    this.setState({ currentSessionData: { short: '' } });
    this.setState({ videoKey: '' });
    this.setState({ subtitle: '' });
    this.checkConditionData(valueData,currentSessionDataValue);
  } else {
    this.setState({ currentSessionData: currentSessionDataValue.currentVideo });
    this.setState({ videoKey: currentSessionDataValue.currentVideo.short });
    this.checkConditionData(valueData,currentSessionDataValue );
  }
  
};
  updateSelectedValue = (valueData) => { 
    if (valueData === 'AM') {
      this.setState({ defualtHeading: 'Good Morning!' });
      this.setState({ currentSelectedSession: valueData });
      this.updatePageData(valueData);
    } else if (valueData === 'PM') {
      this.setState({ defualtHeading: 'Good Evening!' });
      this.setState({ currentSelectedSession: valueData });
      this.updatePageData(valueData);
    } else {
      this.setState({ currentSelectedSession: valueData });
      this.setState({ defualtHeading: 'Welcome!' });
      this.setPopupTemplate('');
    }
   
  };
  sessionPlay = (videoSession, videoType) => {
      this.props.updateCheckingData('yes','/mindful-brushing/PlaySession?videoType='+videoType);
      this.setState({ videoKey: videoSession.short });
      this.setState({ videoSession: videoSession });
      this.enableDiv('videoView');
  };

  enableDiv(divName) {
  //  let redirectPage = '';
    if (divName === 'videoView' && this.state.videoSession.short === '') {
      this.props.updateCheckingData('','');
      this.setState({ viewTemplate: 'sessionView' });
    } else if (divName === 'feedbackView')
    {
      this.setState({ viewTemplate: divName });
    }
    else if(  divName === 'videoView') {
      this.setState({ viewTemplate: divName });
    } else {
      this.props.updateCheckingData('', '');
      this.setState({ viewTemplate: divName });
    }
  }
  enableFeedback = (videoType,videoKey) => {
    let redirectPage = '/mindful-brushing/PlaySession?videoType='+videoType+'_'+ videoKey;
    this.props.updateCheckingData('yes', redirectPage );
    this.setState({ videoKey: videoKey });
    this.enableDiv('feedbackView');
  };

  checkConditionData = (valueData,currentSessionDataValue) => {
    let viewTemplate = '';
    if (currentSessionDataValue.pendingFeedBack !== 'no') {
      viewTemplate = 'feedbackView';
      this.enableFeedback(valueData,currentSessionDataValue.currentVideo.short);
    } 
   else {
      viewTemplate = 'sessionView';
      this.enableDiv(viewTemplate);
    }
    if (viewTemplate === 'sessionView') {
      if (
        currentSessionDataValue.totalCompletedVideo === 1 ||
        currentSessionDataValue.totalCompletedVideo === 3
      ) {
        if (
          currentSessionDataValue.sessionValue['dontAskMe'] === 'yes' ||
          (currentSessionDataValue.totalCompletedVideo === 1 &&
            currentSessionDataValue.sessionValue['dontAskMeVideo2Completed'] === 'yes')
        ) { this.setPopupTemplate('');
        } else if (
          currentSessionDataValue.totalCompletedVideo === 1 && ( currentSessionDataValue.sessionValue['profile'] == null ||
          currentSessionDataValue.sessionValue['profile'] === undefined)
        ) {
          this.setPopupTemplate('emailView');
        }
        else if (
          currentSessionDataValue.totalCompletedVideo === 3 && ( currentSessionDataValue.sessionValue['profile'] == null ||
          currentSessionDataValue.sessionValue['profile'] === undefined)
        ) {
            this.setPopupTemplate('emailView2');
        }
      } else if (currentSessionDataValue.totalCompletedVideo === 5) {
        if (
          currentSessionDataValue.sessionValue['brandAffinityQ1FeedBack'] == null ||
          currentSessionDataValue.sessionValue['brandAffinityQ1FeedBack'] === undefined
        ) {
          this.setPopupTemplate('brandAffinityQ1View');
        } else if (
          currentSessionDataValue.sessionValue['brandAffinityQ2FeedBack'] == null ||
          currentSessionDataValue.sessionValue['brandAffinityQ2FeedBack'] === undefined
        ) {
          this.setPopupTemplate('brandAffinityQ2View');
        } else {
          this.setPopupTemplate('');
        }
      } else if (currentSessionDataValue.totalCompletedVideo === 6) {
        if (
          currentSessionDataValue.sessionValue['ohImpactFeedback'] == null ||
          currentSessionDataValue.sessionValue['ohImpactFeedback'] === undefined
        ) {
          this.setPopupTemplate('ohImpactView');
        } else if (
          currentSessionDataValue.sessionValue['ohImprovementFeedback'] == null ||
          currentSessionDataValue.sessionValue['ohImprovementFeedback'] === undefined
        ) {
          this.setPopupTemplate('ohImprovementView');
        }
      } else if (currentSessionDataValue.countCompletedVideo === 7) {
        if (
          currentSessionDataValue.sessionValue['finishedFeedback'] == null ||
          currentSessionDataValue.sessionValue['finishedFeedback'] === undefined
        ) {
          this.setPopupTemplate('finishedFeedbackView');
        } else {
          this.setPopupTemplate('');
        }
      }
    } else {
      this.setPopupTemplate('');
    } 
    this.addHomeScreen();
  };
  enablePopup = (popupName) => {
    this.setPopupTemplate(popupName);
  };
  
setPopupTemplate = (popTemplateName) => {
  this.setState({ popTemplate: popTemplateName });
};

  addHomeScreen=() =>{
//this.setState({ appIconView: 'appIconView' });
    if (localStorage.getItem('appIcon') == null || localStorage.getItem('appIcon') === undefined) {
       if (localStorage.getItem('channel') == 'qr') {
        this.setState({ appIconView: 'qrCodeView' });
      } else {
        this.setState({ appIconView: 'appIconView' });
      }
    }
    else
    {
      this.setState({ appIconViewStatus: 'no' });
    }
  };

  render() {
    return (
      <>
        <div className="mobile-view-container" ref={this.props.innerRef}>
        <div className="curve1"></div>
          <div
            className={this.state.viewTemplate === 'sessionView' ? 'container-fluid' : 'hide-div'}>
            <div className="row">
              <div className="d-flex padd-0">
                <div className="ampm-text">{this.state.defualtHeading}</div>
                <div className="ampm-img">
                  <img src={Image} alt="Lotus"></img>
                </div>
              </div>
            </div>

            <div className="row mt-1">
              <PlayerSection
                sessionType={'AM'}
                allVideo={this.state.allVideo}
                sessionPlay={this.sessionPlay}
                currentSessionData={this.state.currentSessionData}
                currentSelectedSession={this.state.currentSelectedSession}
                updateSelectedValue={this.updateSelectedValue}
              />
            </div>
            <div className="row mt-1">
              <PlayerSection
                sessionType={'PM'}
                allVideo={this.state.allVideo}
                sessionPlay={this.sessionPlay}
                currentSessionData={this.state.currentSessionData}
                currentSelectedSession={this.state.currentSelectedSession}
                updateSelectedValue={this.updateSelectedValue}
              />
            </div>
            <div className={this.state.currentSelectedSession === '' ? 'tap-text' : 'hide-div'}>
              Tap to select your session
            </div>
          </div>

          <div className={this.state.viewTemplate === 'videoView' ? 'container-fluid' : 'hide-div'}>
            {this.state.viewTemplate === 'videoView' && (
              <VideoSession
                enableFeedback={this.enableFeedback}
                videoType={this.state.currentSelectedSession}
                sessionObject={this.state.videoSession}
              />
            )}
          </div>
          {this.state.viewTemplate === 'sessionView' && this.state.appIconViewStatus === 'yes' && this.props.addHomeScreenStatus == 'yes' && (<AppIcon promptToInstall={this.props.promptToInstall} popTemplate={this.state.appIconView}  addHomeScreen={this.addHomeScreen} 
                     />)}
          {this.state.viewTemplate === 'feedbackView' && (
            <VideoFeeback
              updateSelectedValue={this.updateSelectedValue}
              subtitle={this.state.subtitle}
              videoKey={this.state.videoKey}
              videoType={this.state.currentSelectedSession}
            />
          )}
        </div>
       
        {this.state.popTemplate === 'emailView' && (
          <EamilCapture emailPopupClass={'brushingModel'}
            totalCompletedVideo={this.state.currentSessionDataValue.totalCompletedVideo}
            enablePopup={this.enablePopup}
          />
        )}
        {this.state.popTemplate === 'emailView2' && (
          <EamilCapture emailPopupClass={'brushingModel'}
            totalCompletedVideo={this.state.currentSessionDataValue.totalCompletedVideo}
            enablePopup={this.enablePopup}
          />
        )}
        {this.state.popTemplate === 'brandAffinityQ1View' && (
          <BrandAffinityFeedBack
            popTemplate={this.state.popTemplate}
            updateSelectedValue={this.updateSelectedValue}
            currentSelectedSession={this.state.currentSelectedSession}
          />
        )}
        {this.state.popTemplate === 'brandAffinityQ2View' && (
          <BrandAffinityFeedBack
            popTemplate={this.state.popTemplate}
            updateSelectedValue={this.updateSelectedValue}
            currentSelectedSession={this.state.currentSelectedSession}
          />
        )}
        {this.state.popTemplate === 'ohImpactView' && (
          <OhImpact
            updateSelectedValue={this.updateSelectedValue}
            currentSelectedSession={this.state.currentSelectedSession}
          />
        )}
        {this.state.popTemplate === 'ohImprovementView' && (
          <OhImprovement
            updateSelectedValue={this.updateSelectedValue}
            currentSelectedSession={this.state.currentSelectedSession}
          />
        )}
        {this.state.popTemplate === 'finishedFeedbackView' && (
          <FinishedFeedback
            videoType={this.state.currentSelectedSession}
            enablePopup={this.enablePopup}
          />
        )}
        {this.state.popTemplate === 'profileThanksView' && (
          <FinishedThank
            popTemplate={this.state.popTemplate}
            updateSelectedValue={this.updateSelectedValue}
            currentSelectedSession={this.state.currentSelectedSession}
          />
        )}
        {this.state.popTemplate === 'thankView' && (
          <FinishedThank
            popTemplate={this.state.popTemplate}
            updateSelectedValue={this.updateSelectedValue}
            currentSelectedSession={this.state.currentSelectedSession}
          />
        )}

      </>
    );
  }
}

export default React.forwardRef((props, ref) => <SelectSession 
  innerRef={ref} {...props}
/>);