import React from 'react';
import morningTap from '../../../assets/images/Lotus-White-Logo.png';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';
import { CheckHeaderContent } from '../../../services/Utils';
import { useNavigate } from 'react-router-dom';
import '../../../assets/css/theme/brushing/App.scss';
import { pageList } from '../../../services/WebsiteUrl';
/* function used for header of Brushing Layout
props is used for backbutton
*/ 
const Navbar = (propsValue) => {
  const navigate = useNavigate();
  const [pageListData] = React.useState(pageList());
  const pathname = window.location.pathname;
  const [backButton, setBackButton] = React.useState('');
  const [headerClass, setHeaderClass] = React.useState('');
  const [curvedBackground, setCurvedBackground] = React.useState('');
  const [sidebar, setSidebar] = React.useState(false);
  const [deviceTemplate, setDeviceTemplate] = React.useState(propsValue.deviceTemplate);
  const [redirectPage, setRedirectPage] = React.useState('');
  const showSidebar = () => setSidebar(!sidebar);
  const SidebarData = [
    {
      title: 'How it works',
      path:  pageListData['v2HowItWorks'],
      cName: 'nav-text',
      target: ''
    },
    {
      title: 'About Cognitive Hypnotherapy',
      path:  pageListData['v2Hyponotheraphy'],
      cName: 'nav-text',
      target: ''
    },
    {
      title: 'About Our Expert',
      path:  pageListData['v2Expert'],
      cName: 'nav-text',
      target: ''
    },
    {
      title: 'About Clementine',
      target: '_blank',
      path: 'https://clementineapp.com/',
      cName: 'nav-text'
    },
    {
      title: 'T&C',
      path: pageListData['v2TermCondition'],
      cName: 'nav-text',
      target: ''
    },
    {
      title: 'Privacy Policy',
      path: 'https://www.privacy.haleon.com/',
      cName: 'nav-text',
      target: '_blank'
    }
  ];
  const backButtonHandler = () => {
    let returnData = CheckHeaderContent(pathname);
    if (returnData !== undefined ) {
        if(returnData['redirect'] == 'history')
        {
         // window.history.back();
         navigate(-1);
          return;
        }
        else if(returnData['redirect'] === 'same' && redirectPage === '' )
        {
          window.location.reload(false);
        }
        else if ( redirectPage !== '' )
        {
          window.location.href = redirectPage;
        }  
    } 
    else
    {
      window.location.reload(false);
    }
  };
  React.useEffect(() => {
    setDeviceTemplate(propsValue.deviceTemplate);
    let backButtonSet = 'no';
    let theme = 'curvedesign-bg-color-landing';
    let headerClassStr = '';
    if(propsValue.deviceTemplate === 'mobile')
    {
      let returnData = CheckHeaderContent(pathname);
      if(returnData == undefined)
      {
        theme = '';
      }
      else{
        backButtonSet = returnData['backButton'];
        headerClassStr = returnData['headerClass'];
        theme = returnData['theme'];
      }
    }
    else if( propsValue.deviceTemplate === 'desktop')
    { 
      headerClassStr = 'header-mobileheight';
      theme = 'curvedesign-bg-color-landing';
    }

    if(propsValue.updateCheckingData && propsValue.enableBackButton === 'yes')
    {
      backButtonSet = 'yes';
      setRedirectPage(propsValue.redirectPage);
    }
    setHeaderClass(headerClassStr);
    setBackButton(backButtonSet);
    setCurvedBackground(theme);
   });
  return (
    <>
      <div className={deviceTemplate === 'mobile' ? 'header-bg ' + headerClass : 'header-bg header-mobileheight' }>
        <div className="top-nav">
        <div className={deviceTemplate === 'mobile' ? 'nav-back' : 'hide-div'}>
          <div
            className={backButton === 'yes' ? 'back-btn' : 'hide-div'}
            onClick={backButtonHandler}></div>
            </div>
          <div className="logo" >
            <img src={morningTap} alt="LOTUS" className=""></img>.
          </div>
          <div className={deviceTemplate === 'mobile' ? 'left-menu-icon' : 'hide-div'}>
            <span onClick={showSidebar}>&#8230;</span>
          </div>
        </div>
        <div className={'curvedesign ' + curvedBackground}></div>
      </div>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className="nav-menu-items" onClick={showSidebar}>
            <li className="navbar-toggle close-cross-icon">
              <Link to="#" className="menu-bars"></Link>
            </li>
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path} target={item.target}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
};
export default Navbar;
