import React from 'react';
import { useTranslation } from 'react-i18next';
import haleonLogo from '../../assets/images/term-haleon-logo.svg';

const TermCondition = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
  });
  return (
    <>
      <div className="mobile-view-container">
        <div className="curve1"></div>
        <div className="static-pages">
          <div className="halleon-logo">
            <img src={haleonLogo} alt="logo"></img>
          </div>
          <h3 className="walkthrowHeading">{t('termc.heading1')}</h3>
          <p className="link-heading">{t('termc.updatedDate')}</p>
          <p>{t('termc.p1text1')}</p>
          <p>{t('termc.p1text2')}</p>
          <p className="link-heading">{t('termc.heading2')}</p>
          <p>{t('termc.p2text1')}</p>
          <p className="link-heading">{t('termc.heading3')}</p>
          <p>
            {t('termc.p3text1')}
            <a href="https://www.haleon.com/contact-us/general-enquiry/"> {t('termc.form')}</a>.
          </p>
          <p className="link-heading">{t('termc.heading4')}</p>
          <p className="text-color-black-common-properties">
            {t('termc.p4text1')} <a href="https://www.privacy.haleon.com/">{t('termc.p4anchor')}</a>
            . {t('termc.p4text1remain')}
          </p>
          <p className="link-heading">{t('termc.heading5')}</p>
          <p className="text-color-black-common-properties">{t('termc.p5text1')}</p>
          <p className="link-heading">{t('termc.heading6')}</p>
          <p className="text-color-black-common-properties">{t('termc.p6text1')}</p>
          <p className="link-heading">{t('termc.heading7')}</p>
          <p className="text-color-black-common-properties">{t('termc.p7text1')}</p>
          <p className="text-color-black-common-properties">{t('termc.p7text2')}</p>
          <p className="link-heading">{t('termc.heading8')}</p>
          <p className="text-color-black-common-properties">{t('termc.p8text1')}</p>
          <p className="text-color-black-common-properties">{t('termc.p8text2')}</p>
          <p className="text-color-black-common-properties">{t('termc.p8text3')}</p>

          <p className="link-heading">{t('termc.heading9')}</p>
          <p>{t('termc.p9text1')}</p>
          <p className="link-heading">{t('termc.heading10')}</p>
          <p>{t('termc.p10text1')}</p>
          <p className="link-heading">{t('termc.heading11')}</p>
          <p>{t('termc.p11text1')}</p>
          <p>{t('termc.p11text2')}</p>

          <p className="link-heading">{t('termc.heading12')}</p>
          <p>{t('termc.p12text1')}</p>
          <p className="link-heading">{t('termc.heading13')}</p>
          <p className="text-color-black-common-properties">{t('termc.p13text1')}</p>
          <p>{t('termc.p13text2')}</p>
        </div>
      </div>
    </>
  );
};

export default TermCondition;
