import React from 'react';
import Navbar from './brushing/Navbar';
import NaturalsHeader from './naturals/NaturalsHeader';
import { useSearchParams } from 'react-router-dom';
import { CheckHeaderContent, GetThemeValue,  SetThemeValue  } from '../../services/Utils';
/* this component is used for Brushing default layout having header, footer */
const Layout=({ children }) =>{
  const [searchParams] = useSearchParams();
  const [lotusVersion,setLotusVersion] = React.useState('');
  const [themeName, setThemeName] = React.useState('');
  const pathname = window.location.pathname;
  const [backButton, setBackButton] = React.useState('');
  const [headerClass, setHeaderClass] = React.useState('');
  const [headerEnabled, setHeaderEnabled] = React.useState(true);
  const [deviceTemplate, setdeviceTemplate] = React.useState(children.props.deviceTemplate);
  const [enableBackButton, setEnableBackButton] = React.useState(children.props.enableBackButton);
  const [redirectPage, setRedirectPage] = React.useState(children.props.redirectPage);
    React.useEffect(() => {
      let themeValue = searchParams.get('theme');
      SetThemeValue(themeValue);
      setThemeName(GetThemeValue());
      setdeviceTemplate(children.props.deviceTemplate);
      setEnableBackButton(children.props.enableBackButton);
      setRedirectPage(children.props.redirectPage);
      let returnData = CheckHeaderContent(pathname);
       setBackButton(returnData.backButton);
       setHeaderClass(returnData.headerClass);
       setHeaderEnabled(returnData.headerEnabled)
       setLotusVersion(returnData.lotusVersion);
      });
    if(lotusVersion === 'v3' )
    {
      return( 
        <div className={'lotus-'+themeName}>
          <div className="bg-landing">
            <NaturalsHeader headerEnabled={headerEnabled} theme={themeName} headerClass={headerClass} backButton={backButton}/>
            <div className={headerClass=='landing'?'home-mid-container' : 'home-mid-container bg-inner-page'}>{headerEnabled === true ? ( <div className="top-curve "></div> ): ('')}
              <main>{children}</main>
          </div>
        </div>
        </div>
        );
    }
    else
    {
      return (
        <div className="mobile-view">
                <Navbar layoutName="brushing" redirectPage={redirectPage}
                deviceTemplate={deviceTemplate} enableBackButton={enableBackButton} updateCheckingData={children.props.updateCheckingData} />
                <main>{children}</main>
          </div> 
        );
    }
};
export default Layout;