import React from 'react';
import clamintinelogo from '../../assets/images/images-v3/clementine-logo1.svg';
import infoicon from '../../assets/images/images-v3/info-icon.svg';
import infoBlueicon from '../../assets/images/images-v3/info-icon-blue.svg';
//import expert from '../../assets/images/excutive-pic.png';
//import expert1 from '../../assets/images/images-v3/emily-blue.png';
import { GetThemeValue, MusicHeaderContent } from '../../services/Utils';
import { pageList } from '../../services/WebsiteUrl';
import { SetVoltaraNaturalFeedBack } from '../../services/Session';
import VideoPlayer from '../../components/VideoPlayer';
import AudioPlayer from '../../components/AudioPlayer';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {SetLocalStorage} from '../../services/Session';
const NaturalsTheraphy = () => {
  const { t } = useTranslation('naturals');
  const navigate = useNavigate();
  const [theme] = React.useState(GetThemeValue);
  const [pageListData] = React.useState(pageList());
  const [searchParams] = useSearchParams();
  const [videoName,setVideoName] = React.useState('');
  const [videoId,setVideoId] = React.useState('');
  const [playerType,setPlayerType] = React.useState('');
  React.useEffect(() => {
    let videoIdData = searchParams.get('id');
    if(videoIdData === null || videoIdData === undefined )
    { 
      navigate(pageListData['v3Session']);
    }
    else
    {
      let returnData = MusicHeaderContent(videoIdData);
      if(theme === 'green')
      {
        setVideoName(returnData.musicGreen);
      }
      else
      {
        setVideoName(returnData.musicBlue);
      }
      setPlayerType(returnData.playerType);
      setVideoId(videoIdData);
    }
   
  },[]);

  const endPlayerCallback = () => {
    SetLocalStorage('subtitle','no');
    let feedBackPending = {'pendingVideo': videoId};
    SetVoltaraNaturalFeedBack(feedBackPending);
    navigate(pageListData['v3Emotions']+'?id='+videoId);
  };
if(videoName !== '' )
{
  return (
    <>
     <div className="theraphy">
     {
     playerType==='video' ?
     (
      <VideoPlayer videoId={videoName} endPlayerCallback={endPlayerCallback} videoVersion={'v3-voltanatural'}/>)
    :(
      <AudioPlayer audioId={videoName} endPlayerCallback={endPlayerCallback}/>)
    }
    </div>

    {videoId === 'PromoteRelaxation' ? (<div className="theraphy-footer">
        <div >
        <img width={25} src={theme==='green'?infoicon:infoBlueicon} alt="info icon"></img>
        </div>
        <div className="font-14">{t('sessionPage.theraphy')}
        </div></div>):(
        <div className="theraphy-footer">
            
              <div className="partner-width d-flex">
                <div className="partner-text">In partnership with</div>
                <span> <img width={125} src={clamintinelogo} alt="Clamintine logo"></img></span>
                {/* <p><span> {t('sessionPage.static1')}</span>  {t('sessionPage.static2')} ( {t('sessionPage.static3')})</p> */}
              </div>
              {/* <div className="m-left-auto">
              <img width={65} src={theme === 'green' ? expert : expert1 } alt="Professional headshot of Emily Hodge smiling"></img>
              </div> */}
        </div>)}
    </>
  );
}
};

export default NaturalsTheraphy;