import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { useTranslation } from 'react-i18next';
const RatingSLider = (propsValue) => {
  const { t } = useTranslation();
  const [min, setMin] =  useState(1);
  const [rating, setRating] = useState(3);
  const [ratingError, setRatingError] = useState('');
  const [ratingClick, setRatingClick] = useState(false);
  React.useEffect(() => {
    
  });
const sendUpdateData = () => {
  let validError = validationCheck();
  if (validError === false) {
    propsValue.finalFeedBack(rating);
  }
};
  const validationCheck = () => {
    let validStatus = false;
    if (rating === 0 || rating === '0' ) {
      validStatus = true;
      setRatingError(t('feedback.ratingErr'));
    }
    else if(ratingClick === false)
    { validStatus = true;
      setRatingError(t('feedback.ratingClickErr'));
    }
    return validStatus;
  };
  const sliderChange = (value) => {
    
    setRatingClick(true);
    if(min === 1)
    {
      setMin(0);
    }

    setRatingError('');
    setRating(value);
  };
  return (
    <>
    <div className="rating-slider">
      <p>Did you find that helpful?</p>
      <div className="rangelabel">
        <p className="text-label">Not at all</p>
        <p className="text-label">Quite a lot</p>
        
      </div>
        <RangeSlider
          min={min}
          max={5}
          tooltip="auto"
          value={rating}
          size='lg'
          onChange={(changeEvent) => sliderChange(changeEvent.target.value)}
        />
     
     <span className="text-color-danger">{ratingError}</span>

      </div>
      <div className="btn-center">
        <button
          type="button" onClick={() => sendUpdateData()}
          className="emotion-btn ">
          {t('button.submit')}
        </button>
      </div>
    </>
  );
};
export default RatingSLider;
