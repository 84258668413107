/* data is push google tag manage */
export const pushInGoggleTag=( valueData, lotusVersion )=>{
    // here dataLayer push as an event, based on this we can get custom trigger in GTM
    
      if( lotusVersion === 'V3')
      {
        valueData.lotusVersion='v3-voltanatural';
       
      }
      else if( lotusVersion === 'V2')
      {
        valueData.lotusVersion='v2-sensodyne';
      }
      if (Array.isArray(window.dataLayer)) {
        window.dataLayer.push(valueData);
      } else {
        // Initialize dataLayer as an array and then push the data
        window.dataLayer = [valueData];
      }
};
