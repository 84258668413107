import React from 'react';
import Brushing from '../../assets/images/tooth brush.svg';
import Clametine from '../../assets/images/clametine-logo.png';
import Seno from '../../assets/images/sensodyne.svg';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SetLocalStorage } from '../../services/Session';
import { defualtPage } from '../../services/WebsiteUrl';
const MobileHome = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const GetMoreFromBrushing = () => {
    let returnData = defualtPage('v2Intro');
    navigate(returnData);
  };
  React.useEffect(() => {
    let channelName = searchParams.get('channel');
    if (channelName === 'qr') {
      SetLocalStorage('channel', 'qr');
    } else {
      SetLocalStorage('channel', 'email');
    }
  });
  return (
    <>
      <div className="light-bg">
        <div className="mid-content mobile-bg">
          <div className="content-center">
            <div className="glow-ellipes">             
              <h1>{t('brushing.heading')}</h1>
              <p>{t('brushing.mindful')}</p>
                         
            </div>
            </div>
            <div className="card-round">
              <div className="brushing">
                <img src={Brushing} alt="Brushing"></img>
              
              </div>
              <div className="btn-center">
              <button
                type="button"
                className="btn btn-primary landing-btnpos"
                onClick={() => GetMoreFromBrushing()}>
                Stress less now
              </button>
            </div>
              </div>
            
          </div>
        
        <div className="footer">
          <p>{t('brushing.partnership')}</p>
          <div className="footer-logo">
            <img src={Clametine} alt="Clametine logo" className="imgwdth"></img> <span>&</span>{' '}
            <img src={Seno} alt="Seno logo"></img>
          </div>
        </div>
      </div>
    </>
  );
};
export default MobileHome;
