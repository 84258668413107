import React from 'react';
//import mediaprev from '../assets/images/media-prev.svg';
import mediaprev from '../assets/images/images-v3/media-prev.svg';
import medianext from '../assets/images/media-next.svg';
import mediaplay from '../assets/images/media-play.svg';
import mediapause from '../assets/images/media-pause.svg';
import mediabluenext from '../assets/images/media-next-blue.svg';
import mediablueprev from '../assets/images/media-prev-blue.svg';
import { GetThemeValue } from '../services/Utils';
import {pushInGoggleTag} from '../services/DataLayerGa';
import {SetLocalStorage} from '../services/Session';
const AudioPlayer = (propsValue) => {
  const [theme] = React.useState(GetThemeValue());
  const audioRef  = React.useRef(null);
  const [imageSrc] = React.useState('../assets/video/VoltaraNaturals/' +propsValue.audioId+'.png');
  const [audioSrc] = React.useState('../assets/video/VoltaraNaturals/' +propsValue.audioId+'.mp3');
  const [trackSrc] = React.useState('../assets/video/VoltaraNaturals/' +propsValue.audioId+'.vtt');
  //const [percentage , setPercentage] = React.useState('0%');
 const [audioPlayStatus,setAudioPlayStatus] = React.useState('no');
 const [currentTime,setCurrentTime] = React.useState(0);
 const [duration,setDuration] = React.useState(0);
 const progressBarRef = React.useRef(null);
  React.useEffect(() => {
    console.log(currentTime);
    console.log(duration);
      SetLocalStorage('subtitle','no');
      //setPercentage(0);
      audioRef.current.load();
      audioTimer();
      const autoplayDelay = 1000; // 3 seconds delay before autoplay (adjust as needed)

      const playVideo = () => {
        audioRef.current.play().then(() => {
          audioRef.current.muted = false;
          setAudioPlayStatus('yes');
        }).
        catch((error) => {
            // Autoplay may be blocked by the browser, handle the error here
           setAudioPlayStatus('no');
            console.error('Autoplay failed:', error);
          });
      };
  
      const timeoutId = setTimeout(playVideo, autoplayDelay);
      return () => {
        clearTimeout(timeoutId); // Clean up the timeout if the component unmounts before autoplay
      };
  },[]);
  const playPauseAudio=()=> {
    if (audioRef.current && audioPlayStatus === 'yes'){
      setAudioPlayStatus('no');
      audioRef.current.pause();
    }
    else
    {
      triggerGoogleTag('play','0%');
      setAudioPlayStatus('yes');
      audioRef.current.play();
    }
  };
  const triggerGoogleTag = (action,dataPercentage)=>
  {
      pushInGoggleTag({
        'event': 'e_video_data',
        'videoProvider':'LotusVideo',
        'videoPercentage':dataPercentage,
        'videoAction': action,
        'videoLabel': propsValue.audioId
        },'V3');
  };

  const handleProgressBarClick = (event) => {
 const progressBar = progressBarRef.current;
  const progressRect = progressBar.getBoundingClientRect();
const clickPosition = event.clientX - progressRect.left;
const progressBarWidth = progressBar.offsetWidth;
const currentTimeData = (clickPosition / progressBarWidth) * audioRef.current.duration;
audioRef.current.currentTime = currentTimeData;
  };
  const handleTimeUpdate = () => {
     if(audioRef.current != null && audioRef.current != undefined)
     {  
        if(audioRef.current.currentTime != 0 && isNaN(audioRef.current.duration) === false){
          setDuration(audioRef.current.duration);
          setCurrentTime(audioRef.current.currentTime );
         
        let t = Math.floor(audioRef.current.currentTime / audioRef.current.duration * 100 );      
        if( t%4 == 0 )
        {
          triggerGoogleTag('progress',t +'%');
        }
        //setPercentage(t +'%');
    }
  }
};
  const audioTimer=()=>{
    if(audioRef != null && audioRef != undefined)
    {
      audioRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }
    else
    {
      audioRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    }
  };
  const onPlay = () => {
    pushInGoggleTag({'event': 'video-status'},'V3');
  }
  
  const handleSeekBackward = () => {
    if(audioRef != null )
    {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime );
        audioRef.current.currentTime -= 5; // Seek 10 seconds backward
      }
    }
  };

  const handleSeekForward = () => {
    if(audioRef != null )
    {
      if (audioRef.current) {
        setCurrentTime(audioRef.current.currentTime );
        audioRef.current.currentTime += 5; // Seek 10 seconds forward
      }
    }
  };

  const endAudioCallback = () => {
    propsValue.endPlayerCallback();
   
  };
  const handleScrubberChange = (e) => {
    
    const newTime = parseFloat(e.target.value);
   // alert(e.target.value);
    setCurrentTime(newTime);
    //setCurrentTime(newTime);
    if (audioRef.current) {
      audioRef.current.currentTime = newTime;
    }
  };

  return (
    <>
   <img src={imageSrc} className="music-img"/>
      <audio ref={audioRef} src={audioSrc} controls={false} controlsList="nodownload" autoPlay={true}  onPlay={() => onPlay()}
        onEnded={() => endAudioCallback()}>
            <track src={trackSrc} kind="captions" label="English" default />
        </audio>
        <div className="audio-cc">
        </div> 
        <div id="progressBar" ref={progressBarRef} className={theme==='green'?'progressBar-green':'progressBar-blue'} onClick={handleProgressBarClick}>
  
          <input type="range" id="scrubSlider" className="scrubSlider1"  onChange={handleScrubberChange} onmin="0" value={currentTime} max={duration} step="0.1"/>
          <div class="range-slider">
            <input type="range" min="0" max={duration} value="0" id="range"  onChange={handleScrubberChange} onmin="0" value={currentTime} max={duration}step="0.1" /> 
          
        </div>
    </div>
    {/*<div className="progress" ref={progressBarRef} onClick={handleProgressBarClick}>
    <div   className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: percentage }} >
  
  </div>
    </div>*/}
      <div className="play-control">
    <div onClick={() => handleSeekBackward()}><img width={42} src={theme==='green'?mediaprev:mediablueprev} alt="Media Prev"></img></div>
    <div className="media-play" onClick={() => playPauseAudio()}><img src={audioPlayStatus=='yes'? mediaplay:mediapause} alt="Media Play"></img></div> 
    <div  onClick={() => handleSeekForward()}><img width={42} src={theme==='green'?medianext:mediabluenext} alt="Media Next"></img></div>
    </div>
    </>
  );
};

export default AudioPlayer;