
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import Prepare from '../assets/images/images-v3/prepare-stimulate-headericon.svg';
import Relax from '../assets/images/images-v3/relax-recover-headericon.svg';
import Soothe from '../assets/images/images-v3/soothe-relieve-headericon.svg';
import PromoteRelaxation from '../assets/images/images-v3/Promote-Relaxation-headericon.svg';
import {backButtonData} from './WebsiteUrl';
const backButtonArray =backButtonData(); 

const musicTheme={
       'Prepare':{playerType:'video',headerImage:Prepare,headerClass:'header-0',headerTranslationGreen:'musicData.prepare.headerTitle.green',headerTranslationBlue:'musicData.prepare.headerTitle.blue',musicGreen:'VOLT-Prepare',musicBlue:'LOTUS-Prepare'},
       'Relax':{playerType:'video',headerImage:Relax,headerClass:'header-1',headerTranslationGreen:'musicData.relax.headerTitle.green',headerTranslationBlue:'musicData.relax.headerTitle.blue',musicGreen:'VOLT-Relax',musicBlue:'LOTUS-Relax'},
       'Soothe':{playerType:'video',headerImage:Soothe,headerClass:'header-2',headerTranslationGreen:'musicData.soothe.headerTitle.green',headerTranslationBlue:'musicData.soothe.headerTitle.blue',musicGreen:'VOLT-Soothe',musicBlue:'LOTUS-Soothe'},
       'PromoteRelaxation':{playerType:'audio',headerImage:PromoteRelaxation,headerClass:'header-3',headerTranslationGreen:'musicData.promoteRelaxation.headerTitle.green',headerTranslationBlue:'musicData.promoteRelaxation.headerTitle.blue',musicGreen:'VOLT-Promote-Relaxation',musicBlue:'LOTUS-Promote-Relaxation'}

};

export const MusicHeaderContent=(musicName)=>{
       if (musicTheme[musicName] !== undefined ) { 
              return(musicTheme[musicName]);
       }
       else
       {
              return({headerClass:'',headerTranslationGreen:'',headerTranslationBlue:''});
       }   

};

export const CheckHeaderContent = (pathName) => {
if (backButtonArray[pathName] !== undefined ) { 
       return(backButtonArray[pathName]);
}
else
{
        return({headerEnabled:true,headerClass:'',backButton:"no",redirect:"no",theme:''});
}
};

export const GetThemeValue = () =>{
       var now = new Date();
       var time = now.getTime();
       var expireTime = time + 1000*31536000;
       now.setTime(expireTime);
     return 'blue';
       /*if(cookies.get('frontend-theme') !=  null && cookies.get('frontend-theme') != undefined &&  cookies.get('frontend-theme') !=  '')
       {
              return (cookies.get('frontend-theme'));
       }
       else if(cookies.get('backend-theme') !=  null && cookies.get('backend-theme') != undefined &&  cookies.get('backend-theme') !=  '')
       {
          
              cookies.set('frontend-theme',cookies.get('backend-theme'), { path: '/',expire : expireTime });
              return (cookies.get('backend-theme'));
       }
       else
       {console.log('testing');
              cookies.set('frontend-theme','green', { path: '/',expire : expireTime });
       }*/
       
};

export const SetThemeValue = (themeName) =>{
       var now = new Date();
       var time = now.getTime();
       var expireTime = time + 1000*31536000;
       now.setTime(expireTime);
if(themeName != null  && themeName != undefined && themeName != ''   )
{
      /* if( themeName === 'green' || themeName === 'blue' )
       {    
              cookies.set('frontend-theme',themeName, { path: '/', expire : new Date() + 365 });
       }
       else
       {     
              cookies.set('frontend-theme','blue', { path: '/',expire : new Date() + 365 })
       }*/
       cookies.set('frontend-theme','blue', { path: '/',expire : new Date() + 365 })
} 
};