import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { SaveLocalStorage, UpdateGigya } from '../services/Session';
import {pushInGoggleTag} from '../services/DataLayerGa';
const VideoFeedback = (propsValue) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(false);
  const [videoKey, setVideoKey] = React.useState('');
  const [subtitle, setSubtitle] = React.useState('off');
  const [videoType, setVideoType] = React.useState(propsValue.sessionType);
  const [min, setMin] =  useState(1);
  const [rating, setRating] = useState(3);
  const [ratingError, setRatingError] = useState('');
  const [ratingClick, setRatingClick] = useState(false);
  const [buttonClass, setButtonClass] = useState('');
  const [buttonError, setButtonError] = useState('');
  const [otherErrorMsg, setOtherErrorMsg] = useState('');
  const [emotionsFeedBackList, setEmotionsFeedBackList] = useState([]);
  const [emotionsFeedback, setEmotionsFeedback] = useState([]);
  const [otherErr, setOtherErr] = useState('');
  const [emotionData, setOtherEmotionData] = useState('');
  const emotionList1 = [
    'Happiness',
    'Calmness',
    'Relaxed',
    'Sadness',
    'Stressed',
    'Bored',
    '+'
  ];
  React.useEffect(() => {
    setVideoType(propsValue.videoType);
    setVideoKey(propsValue.videoKey);
    setSubtitle(propsValue.subtitle);
    if (videoType === 'AM') {
      setButtonClass('morbtn');
    } else {
      setButtonClass('evnbtn');
    }
  });
  const handleClose = () => {
    setShow(false);
  };
  const updateEmotions = (emotionValue) => {
   let emotionsFeedBackListData = [...emotionsFeedBackList];
    let index = emotionsFeedBackListData.indexOf(emotionValue);
    if (index !== -1) {
      emotionsFeedBackListData.splice(index, 1);
      setEmotionsFeedBackList(emotionsFeedBackListData);
    } else {
      emotionsFeedBackListData.push(emotionValue);
      setEmotionsFeedBackList(emotionsFeedBackListData);
    }
    //  setButtonErr('');
    //  setOtherErr('');
    if (emotionValue === 'Other') {
      setShow(true);
      setEmotionsFeedback('');
      setOtherErr('');
    }
  };
  const otherButton = () => {
    if (emotionData === '') {
      setOtherErr(t('feedback.otherErr'));
    } else {
      setEmotionsFeedback(emotionData);
      setShow(false);
    }
  };
  const finalFeedBack = () => {
    let validError = validationCheck();
    if (validError === false) {
      if (emotionsFeedBackList.indexOf('Other') !== -1) {
        SaveLocalStorage(videoType, videoKey, {
          rating: rating,
          emotions: emotionsFeedBackList.toString(),
          otheremotions: emotionsFeedback,
          subtitles: subtitle
        });
        pushInGoggleTag({
          'event': 'feedback_capture',
          videoFeedBackName:videoType + '_'+ videoKey,
          feedbackProvider:"videoFeeback",
          videoFeedback:emotionsFeedBackList.toString(),
          subTitle:subtitle,
          videoRating:rating
         },'V2');
      } else {
        SaveLocalStorage(videoType, videoKey, {
          rating: rating,
          emotions: emotionsFeedBackList.toString(),
          otheremotions: '',
          subtitles: subtitle
        });
        pushInGoggleTag({
          'event': 'feedback_capture',
           videoFeedBackName:videoType + '_'+ videoKey,
           feedbackProvider:"videoFeeback",
           videoFeedback:emotionsFeedBackList.toString(),
           subTitle:subtitle,
           videoRating:rating
          },'V2');
      }
      UpdateGigya('V2','');
      propsValue.updateSelectedValue(videoType);
    }
  };
  const validationCheck = () => {
    let validStatus = false;

    if (emotionsFeedBackList.length === 0) {
      validStatus = true;
      setButtonError(t('feedback.emotionErr'));
    } else if (emotionsFeedback === '' && emotionsFeedBackList.includes('Other')) {
      validStatus = true;
      setOtherErrorMsg(t('feedback.otherEmotionErr'));
    }
    if (rating === 0 || rating === '0' ) {
      validStatus = true;
      setRatingError(t('feedback.ratingErr'));
    }
    else if(ratingClick === false)
    { validStatus = true;
      setRatingError(t('feedback.ratingClickErr'));
    }
    return validStatus;
  };
  const sliderChange = (value) => {
    
    setRatingClick(true);
    if(min === 1)
    {
      setMin(0);
    }

    setRatingError('');
    setRating(value);
  };
  return (
    <>
      <div className="heading-text-title">{t('feedback.checkEmotions')}</div>
      <h2>{t('feedback.improveMood')}</h2>
      <div className="range-details d-flex">
        <p>{t('feedback.notAll')}</p> <p>{t('feedback.quiteLot')}</p>
      </div>
      <div className={videoType === 'PM' ? 'evening-range' : 'morning-range'}>
        <RangeSlider
          min={min}
          max={5}
          tooltip="auto"
          value={rating}
          onChange={(changeEvent) => sliderChange(changeEvent.target.value)}
        />
      </div>
      <span className="text-color-danger">{ratingError}</span>
      <div className="heading2-text">{t('feedback.takeMoment')}</div>
    
      <div className="btn-section d-flex">
        {(() => {
          const arr = [];
          for (let i = 0; i < 7; i++) {
            if (emotionList1[i] === '+') {
              arr.push(
                <button
                  type="button"
                  key={emotionList1[i]}
                  id={emotionList1[i]}
                  className={
                    emotionsFeedBackList.indexOf('Other') !== -1
                      ? buttonClass + ' active'
                      : buttonClass
                  }
                  onClick={() => updateEmotions('Other')}>
                  {emotionList1[i]}
                </button>
              );
            } else {
              arr.push(
                <button
                  type="button"
                  key={emotionList1[i]}
                  id={emotionList1[i]}
                  className={
                    emotionsFeedBackList.indexOf(emotionList1[i]) !== -1
                      ? buttonClass + ' feedback-capture active'
                      : buttonClass + ' feedback-capture '
                  }
                  onClick={() => updateEmotions(emotionList1[i])}>
                  {emotionList1[i]}
                </button>
              );
            }
          }
          return arr;
        })()}
      </div>
      <span className="text-color-danger">{otherErrorMsg}</span>
      <span className="text-color-danger">{buttonError}</span>
      <div className="btn-center">
        <button
          type="button"
          className="btn btn-primary video-btn-mar"
          onClick={() => finalFeedBack()}>
          {t('button.submit')}
        </button>
      </div>
      <Modal className="brushingModel" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="heading-text-title-popup">Add other emotion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <input
              type="Text"
              placeholder="Please let us know...."
              className="form-control custom-input"
              value={emotionData}
              onChange={(changeEvent) => setOtherEmotionData(changeEvent.target.value)}></input>
          </div>

          <div className="btn-center mar-btn">
            <button type="button" className="pop-button-ohimpact" onClick={() => otherButton()}>
              {' '}
              {t('button.add')}
            </button>
          </div>
          <span className="text-color-danger">{otherErr}</span>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default VideoFeedback;
