import React from 'react';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';
import { pageList } from '../../../services/WebsiteUrl';
/* function used for header of Brushing Layout
props is used for backbutton
*/ 
const MenuSidebar = (propsValue) => {
  const [pageListData] = React.useState(pageList()); 
  const SidebarData = [
    {
      title: 'How it works',
      path: pageListData['v3HowItWorks'],
      cName: 'nav-text',
      target: ''
    },
    {
      title: 'About Clementine',
      target: '_blank',
      path: 'https://clementineapp.com/',
      cName: 'nav-text'
    },
    {
      title: 'T&C',
      path: pageListData['v3TermCondition'],
      cName: 'nav-text',
      target: ''
    },
    {
      title: 'Privacy Policy',
      path: 'https://www.privacy.haleon.com/',
      cName: 'nav-text',
      target: '_blank'
    }
  ];
  return (
    <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className={propsValue.sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className="nav-menu-items" onClick={propsValue.showSidebar}>
           
            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <Link to={item.path} target={item.target}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
};
export default MenuSidebar;
