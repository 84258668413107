import React from 'react';
import Slider from 'react-slick';
import { defualtPage } from '../../services/WebsiteUrl';
import morningTap from '../../assets/images/morning-image.svg';
import eveningTap from '../../assets/images/evening-illustration.svg';
import taptheCap from '../../assets/images/tooth-brush-background-illustration.svg';
import { useNavigate } from 'react-router-dom';
import { SetLocalStorage } from '../../services/Session';
import { useTranslation } from 'react-i18next';
import Hypnotherapy1 from '../../assets/images/therapies-illustration.svg';
import Hypnotherapy2 from '../../assets/images/brain-illustration.svg';
import Hypnotherapy3 from '../../assets/images/experts-illustration.png';

import 'bootstrap/dist/css/bootstrap.css';
const getWalkThroughItems = () => [
  {
    id: '1',
    src: taptheCap,
    heading: '7 topics to grow stronger each time you brush',
    body: 'Build your resilience to daily stresses with sessions covering:',
    body1:' Self-compassion, confidence, gratitude, calm, self-talk, savouring and comparison.'
  },
  {
    id: '2',
    src: morningTap,
    heading: 'Add a positive start to every morning',
    body: 'Prepare for whatever the day may throw at you with these 2 minute easy mindfulness sessions.'
  },
  {
    id: '3',
    src: eveningTap,
    heading: 'Unwind your mind at the end of the day',
    body: 'Get your body and mind ready for a refreshing sleep with a relaxation mindfulness sessions.'
  }
];

const getHypotherpyItems = () => [
  {
    id: '1',
    src: Hypnotherapy1,
    heading: 'A blend of 5 science-backed therapies',
    body: 'Cognitive Hypnotherapy blends multiple types of proven therapies, including Cognitive Behavioural Therapy (CBT) and Neuro-Linguistic Programming (NLP).'
  },
  {
    id: '2',
    src: Hypnotherapy2,
    heading: 'Transforming the way you think.',
    body: 'It teaches your mind by interrupting negative thoughts and replacing them with more positive ones until they become your default way of thinking.'
  },
  {
    id: '3',
    src: Hypnotherapy3,
    heading: 'Created and curated by experts, for you.',
    body: ' All scripts are written and recorded by trained cognitive hypnotherapists because the delivery is as important as the words.'
  }
];

const WalkThrough = () => {
  const [slideIndex, setSlideIndex] = React.useState(0);
  const [pageString, setPageString] = React.useState('');
  const { t } = useTranslation();
  const navigate = useNavigate();
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      setSlideIndex(newIndex);
    }
  };
  const [walkThrough] = React.useState(getWalkThroughItems());
  const [hypoTherpy] = React.useState(getHypotherpyItems());
  const [items, setItemsData] = React.useState(getWalkThroughItems());
  const BeginSession = () => {
    SetLocalStorage('sesBegin', 'yes');
    let returnData = defualtPage('v2Session');
    navigate(returnData);
   
  };
  React.useEffect(() => {
    const pathname = window.location.pathname;
   if (
      pathname === '/mindful-brushing/Howitworks' ||
      pathname === '/mindful-brushing/WalkThrough'
    ) {
      setItemsData(walkThrough);
    } else {
      setItemsData(hypoTherpy);
    }
    setPageString(pathname);
   
   
  });
  return (
    <>
      <div className="mobile-view-container">
      <div className="curve1"></div>
        <Slider {...settings}>
          {items.map((item) => (
            <div className="card-gap" key={item.id + '1'}>
              <div key={item.id} className="slider-card cardpadding">
                <h4>{item.heading}</h4>
                <p>{item.body}
                <span>{item.body1}</span>
                </p>
                               <div className="content-center-for-remaining-screen">
                  <img className="Image Round" src={item.src} alt="content description" />
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {pageString === '/mindful-brushing/WalkThrough' && slideIndex === 2 && (
          <div className="btn-center btn-position">
            <button className="btn btn-primary btnwidth90" onClick={() => BeginSession()}>
              {t('walkthrough.Begin_sessions')}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default WalkThrough;
