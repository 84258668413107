import React from 'react';
import expert from '../../assets/images/images-v3/Emily-Hodge.jpg';
import expert1 from '../../assets/images/images-v3/Emily-Hodge-blue.jpg';
import {GetThemeValue}  from '../../services/Utils';
import { useTranslation } from 'react-i18next';
const NaturalsExpert = () => {
  const { t } = useTranslation('naturals');
const [theme] = React.useState(GetThemeValue());
  React.useEffect(() => {
  });
  return (
    <>
     <div className="expert">
     <img src={theme === 'green' ? expert : expert1 } alt="Professional headshot of Emily Hodge smiling"></img>
     <p><span>{t('aboutUs.ptext1')}</span>{t('aboutUs.ptext2')}</p>

     </div>
     <div className="static-content">
      <div className="heading">{t('aboutUs.ptext3')}</div>
      <p>{t('aboutUs.ptext4')}</p>
      <p>{t('aboutUs.ptext5')}</p>
      <p>{t('aboutUs.ptext6')}</p>

     </div>
    </>
  );
};

export default NaturalsExpert;
