import React from 'react';
import ReactDOM from 'react-dom/client';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import TagManager from 'react-gtm-module';
import './i18n/config';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const gtMCode = ((window.location.href.indexOf('nextlotus-uat')> -1) ||
(window.location.href.indexOf('mylotus')> -1 )) ? 'GTM-T3VQF68' : 'GTM-MLD3K9L';

const tagManagerArgs = {
  gtmId: gtMCode //'GTM-T3VQF68' //'GTM-MLD3K9L'
};
console.log(gtMCode);
TagManager.initialize(tagManagerArgs);
const rootId = document.getElementById('root');
const root = ReactDOM.createRoot(rootId);
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
        <App />
    </I18nextProvider>
  </React.StrictMode>
);

// call here for PWA purpose
serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
