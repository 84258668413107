import React from 'react';
 import EntryPopup from '../../components/popups/naturals/EntryPopup';
import preparecard1 from '../../assets/images/images-v3/prepare-stimulate-green.png';
import relaxrecovercard2 from '../../assets/images/images-v3/relax-recover-green.png';
import sootherelievecard3 from '../../assets/images/images-v3/soothe-relieve-green.png';
import promoterelaxationcard3 from '../../assets/images/images-v3/Promote-Relaxation-green.png';
import preparecardBlue from '../../assets/images/images-v3/PrepareAndStimulate-blue.svg';
import relaxrecovercardBlue from '../../assets/images/images-v3/RelaxAndRecover-blue.svg';
import sootherelievecardBlue from '../../assets/images/images-v3/SootheAndRelieve-blue.svg';
import promoterelaxationcardBlue from '../../assets/images/images-v3/PromoteRelaxation-blue.svg';
import { useNavigate } from 'react-router-dom';
import { GetThemeValue } from '../../services/Utils';
import { defualtPage } from '../../services/WebsiteUrl';
import { useTranslation } from 'react-i18next';
import {SetLocalStorage} from '../../services/Session';
const NaturalsHome = (propsValue) => {
  const { t } = useTranslation('naturals');
  const [theme] = React.useState(GetThemeValue());
  const navigate = useNavigate();
  const [popTemplate,setPopTemplate] = React.useState('emptyView');  
  const handleInstallClick = () => {
    
    propsValue.promptToInstall();
  };
  React.useEffect(() => {
    SetLocalStorage('subtitle','no');
   if (localStorage.getItem('appIcon') == null || localStorage.getItem('appIcon') === undefined) {
        setPopTemplate('appIconView');
   }
   });


  const videoPage = (videoName) => {
    let returnData = defualtPage('v3Video');
    let url = returnData+'?id='+videoName;
    navigate(url);
    return;
  };

  return (
    <>
  <div className="video-home">
    <h1>{t('musicData.heading1')}</h1>
    <div className="mindful-section">
    <div className="mindful-video-card cardorange" onClick={() => videoPage('Prepare')}>
      <div className="card-img"><img src={theme==='green'? preparecard1 : preparecardBlue} alt="Illustration of two hands" /></div>
      <div className="card-details">
        <div className="card-heading">{theme==='green'?t('musicData.prepare.headerTitle.green'):t('musicData.prepare.headerTitle.blue')}</div>
        <div className="card-description">{t('musicData.prepare.desc')}</div>
      </div>
    </div>

    <div className="mindful-video-card cardblue cardblue2" onClick={() => videoPage('Relax')}>
      <div className="card-img"><img src={ theme==='green'?relaxrecovercard2:relaxrecovercardBlue} alt="A circle with a number in it" /></div>
      <div className="card-details">
        <div className="card-heading">{theme==='green'?t('musicData.relax.headerTitle.green'):t('musicData.relax.headerTitle.blue')}</div>
        <div className="card-description">{t('musicData.relax.desc')} </div>
      </div>
    </div>


    <div className="mindful-video-card cardgreen cardblue3" onClick={() => videoPage('Soothe')}>
      <div className="card-img"><img src={ theme==='green'?sootherelievecard3 : sootherelievecardBlue} alt="A glowing light" /></div>
      <div className="card-details">
        <div className="card-heading">{theme==='green'?t('musicData.soothe.headerTitle.green'):t('musicData.soothe.headerTitle.blue')}</div>
        <div className="card-description">{t('musicData.soothe.desc')}</div>
      </div>
    </div>

    <h1 className="mar-left-0">{t('musicData.heading2')}</h1>
    <div className="mindful-video-card carddkgreen cardblue4" onClick={() => videoPage('PromoteRelaxation')}>
      <div className="card-img"><img src={theme==='green'? promoterelaxationcard3:promoterelaxationcardBlue} alt="Illustration of a music note" /></div>
      <div className="card-details">
        <div className="card-heading">{theme==='green'?t('musicData.promoteRelaxation.headerTitle.green'):t('musicData.promoteRelaxation.headerTitle.blue')}</div>
        <div className="card-description">{t('musicData.promoteRelaxation.desc')}</div>
      </div>
    </div>

    </div>
          </div>
          {(popTemplate === 'appIconView' || popTemplate === 'qrCodeView' ) && (
          <EntryPopup popTemplate={"appIconView"} promptToInstall={handleInstallClick} />
       ) }
           
    </>
  );
};
export default NaturalsHome;
