import React from 'react';
//import { useNavigate } from 'react-router-dom';
const DefaultPage = () => {
 // const navigate = useNavigate();
  React.useEffect(() => {
   // alert('dfgdfgfg');
   // navigate('/mindful-brushing');
   window.location.href='/mindful-brushing';
  });
  return (
    <>
      <div className="mobile-view-container">
      sdassfsfdfdff
      </div>
    </>
  );
};

export default DefaultPage;
