import axios from 'axios';

let authToken = '';

export const createV3Account = async (v3Data) => {
console.log(v3Data);
};
export const createLiteAccount = async () => {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: process.env.REACT_APP_GET_TOKEN,
    headers: {
      'Content-Type': 'application/json'
    },
  };

  return axios.request(config)
    .then((response) => {
      authToken = response.data.token
      getAPiJsonData();
    }).catch((error) => {

      console.log(error);
    });
};

async function getAPiJsonData() {

  let sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
  if (sessionValue['profileSessionData'] == undefined) {
    let ProfileJsonData = {};
    ProfileJsonData.profile = sessionValue.profile;

    ProfileJsonData.url = process.env.REACT_APP_CREATE_PROFILE_URL;
    let profileData = await callBackApi(ProfileJsonData);
    console.log(Object.keys(profileData).length);
    if (Object.keys(profileData).length > 1) {
      sessionValue['profileSessionData'] = 1;
      localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    }

    await updateVideoData();
  }
  else if (sessionValue['brandAffinityQ1FeedBack'] != undefined &&
    sessionValue['brandAffinityOneFeedBackData'] == undefined) {
    sessionValue['brandAffinityOneFeedBackData'] = 1;
    localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    await updateVideoData();

    let brandAffinityOneData = {};
    brandAffinityOneData.profile = sessionValue.profile;
    brandAffinityOneData.brandAffinityQ1FeedBack = sessionValue.brandAffinityQ1FeedBack;
    brandAffinityOneData.url = process.env.REACT_APP_SAVE_BRAND_DAFFINITY_URL;

    await callBackApi(brandAffinityOneData);
  } else if (sessionValue['brandAffinityQ2FeedBack'] != undefined &&
    sessionValue['brandAffinityTwoFeedBack'] == undefined) {
    sessionValue['brandAffinityTwoFeedBack'] = 1;
    localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    await updateVideoData();

    let brandAffinityTwoData = {};
    brandAffinityTwoData.profile = sessionValue.profile;
    brandAffinityTwoData.brandAffinityQ2FeedBack = sessionValue.brandAffinityQ2FeedBack;
    brandAffinityTwoData.url = process.env.REACT_APP_SAVE_BRAND_DAFFINITY_URL;

    await callBackApi(brandAffinityTwoData);
  } else if (sessionValue['ohImpactFeedback'] != undefined && sessionValue['ohImpactFeedbackData'] ==
    undefined) {
    sessionValue['ohImpactFeedbackData'] = 1;
    localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    await updateVideoData();

    let ohImpactData = {};
    ohImpactData.profile = sessionValue.profile;
    ohImpactData.ohImpactFeedback = sessionValue.ohImpactFeedback;
    ohImpactData.url = process.env.REACT_APP_SAVE_BRAND_DAFFINITY_URL;

    await callBackApi(ohImpactData);

  } else if (sessionValue['ohImprovementFeedback'] != undefined && sessionValue['ohImprovementFeedbackData'] ==
    undefined) {
    {
      sessionValue['ohImprovementFeedbackData'] = 1;
      localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
      await updateVideoData();

      let ohImprovementFeedback = {};
      let ohImprovementBrushForData = {};

      ohImprovementFeedback.iBrushFor = sessionValue.ohImprovementFeedback['iBrushFor'];
      ohImprovementFeedback.iBrush = sessionValue.ohImprovementFeedback['iBrush'];
      ohImprovementFeedback.iEnjoyBrushing = sessionValue.ohImprovementFeedback['iEnjoyBrushing'];
      ohImprovementBrushForData.url = process.env.REACT_APP_SAVE_BRAND_DAFFINITY_URL;
      ohImprovementBrushForData.ohImprovementFeedback = ohImprovementFeedback;
      ohImprovementBrushForData.profile = sessionValue.profile;
      await callBackApi(ohImprovementBrushForData);
    }
  }
  else if (sessionValue['AmfinishedTherapie'] != undefined ||
    sessionValue['PmfinishedTherapie'] != undefined) {

    await updateVideoData();

    let finishedTherapieData = {};
    finishedTherapieData.profile = sessionValue.profile;
    if (sessionValue['AmfinishedTherapie'] != undefined && sessionValue['AmFinishedTherapieFeedBackData'] == undefined) {
      finishedTherapieData.finishedTherapie = sessionValue.AmfinishedTherapie;
      sessionValue['AmFinishedTherapieFeedBackData'] = 1;
      finishedTherapieData.url = process.env.REACT_APP_SAVE_BRAND_DAFFINITY_URL;
      localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
      await callBackApi(finishedTherapieData);
    }
    if (sessionValue['PmfinishedTherapie'] != undefined && sessionValue['PmFinishedTherapieFeedBackData'] == undefined) {
      finishedTherapieData.finishedTherapie = sessionValue.PmfinishedTherapie;
      sessionValue['PmFinishedTherapieFeedBackData'] = 1;
      finishedTherapieData.url = process.env.REACT_APP_SAVE_BRAND_DAFFINITY_URL;
      localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
      await callBackApi(finishedTherapieData);
    }


  }

  else {
    await updateVideoData();
  }
}


function callBackApi(apiJsonData) {
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: apiJsonData.url,
    headers: {
      Authorization: authToken,
      'Content-Type': 'application/json'

    },
    data: apiJsonData
  };

  return axios.request(config)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return error
    });
}

async function updateVideoData() {
  let sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
  let videoJsonData = {};
  videoJsonData.AM = sessionValue.AM;
  videoJsonData.PM = sessionValue.PM;
  videoJsonData.AMWatchVideoCount = Object.keys(sessionValue.AMWatchVideoCount).length == 0 ? {} : sessionValue.AMWatchVideoCount;
  videoJsonData.PMWatchVideoCount = Object.keys(sessionValue.PMWatchVideoCount).length == 0 ? {} : sessionValue.PMWatchVideoCount;
  videoJsonData.profile = sessionValue.profile;
  videoJsonData.url = process.env.REACT_APP_SAVE_VIDEO_FEED_BACK_URL;

  let videodata = await callBackApi(videoJsonData);
  if (Object.keys(videodata).length > 0) {
    let sessionValueNew = JSON.parse(localStorage.getItem('sessionValue'));
    sessionValueNew['AMWatchVideoCount'] = videodata.data.AMWatchVideoCount;
    sessionValueNew['PMWatchVideoCount'] = videodata.data.PMWatchVideoCount;
    localStorage.setItem('sessionValue', JSON.stringify(sessionValueNew));
  }
}