import React from 'react';
import { SetSessionView } from '../../services/Session';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {pushInGoggleTag} from '../../services/DataLayerGa';

const BrandAffinityFeedBack = (propsValue) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(true);
  const [title, setTitle] = React.useState(true);
  const [brandAffinity, setBrandAffinity] = React.useState(propsValue.popTemplate);
  const [selectedValue, setSelectedValue] = React.useState('');
  const feedbackArray = {
    brandAffinityQ1View: {
      LIKESENSODYNE: t('brandQ1Feedback.likeSensodyne'),
      UNCHANGED: t('brandQ1Feedback.unChanged'),
      SENSODYNEFAVOURABLE:t('brandQ1Feedback.sensodyneFavourable'),
    },
    brandAffinityQ2View: {
      LIKESENSODYNE: t('brandQ1Feedback.likeSensodyne2'),
      UNCHANGED: t('brandQ1Feedback.unChanged'),
      SENSODYNEFAVOURABLE:t('brandQ1Feedback.sensodyneFavourable2'),
    }
  };
 
  const closeWindowData = () => {
    setShow(false);
  };
  const updateFeedBack = (feedBackData) => {
    setSelectedValue(feedBackData);
    if (propsValue.popTemplate === 'brandAffinityQ1View') {
      pushInGoggleTag({
        popupQuestion:title,
        'event': 'popup_feedback',
        popupFeedback:'popupFeedback',
        feedbackName:"brandAffinityQ1",
        feedbackValue:feedBackData
       },'V2');
    
      SetSessionView('brandAffinityQ1FeedBack', feedBackData);
    } else {
      pushInGoggleTag({
        'event': 'popup_feedback',
        'lotusVersion':'v2-sensodyne',
        popupQuestion:title,
        popupFeedback:'popupFeedback',
        feedbackName:"brandAffinityQ2",
        feedbackValue:feedBackData
       },'V2');
      SetSessionView('brandAffinityQ2FeedBack', feedBackData);
    }
    propsValue.updateSelectedValue(propsValue.currentSelectedSession);
  };
  React.useEffect(() => {
    setBrandAffinity(propsValue.popTemplate);
    if (propsValue.popTemplate === 'brandAffinityQ1View') {
      setTitle(t('brandQ1Feedback.modelTitle'));
    } else {
      setTitle(t('brandQ2Feedback.modelTitle'));
    }
  });
  return (
    <>
      <Modal className="brushingModel" show={show} onHide={closeWindowData}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="btn-center f-left">
            {Object.keys(feedbackArray[brandAffinity]).map((key) => {
              return (
                <button
                  key={'button' + key}
                  id={'button' + key}
                  className={
                    selectedValue === feedbackArray[brandAffinity][key]
                      ? 'pop-button-ohimpact active popup-feedback'
                      : 'pop-button-ohimpact popup-feedback'
                  }
                  shape="round"
                  size="large"
                  onClick={() => updateFeedBack(feedbackArray[brandAffinity][key])}>
                  {feedbackArray[brandAffinity][key]}
                </button>
              );
            })}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BrandAffinityFeedBack;
