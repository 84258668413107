import React from 'react';
import { Modal } from 'react-bootstrap';
import { RemoveSessionView, SetSessionView } from '../../services/Session';
import {pushInGoggleTag} from '../../services/DataLayerGa';
import { useTranslation } from 'react-i18next';

const FinishedFeedback = (propsValue) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(true);
  const closeWindowData = () => {
    setShow(false);
  };
  const [selectedValue, setSelectedValue] = React.useState('');
  const [newSession, setNewSession] = React.useState('I would like a different 7 sessions');
  const [oldSession, setOldSession] = React.useState('I’d happily repeat the 7 sessions');
  const updateFeedBack = (feedBackData) => {
    setSelectedValue(feedBackData);
    if(propsValue.videoType === 'AM')
    {
      pushInGoggleTag({
        'event': 'popup_feedback',
        popupQuestion:t('finishedFeedback.amCompleteTitle'),
        popupFeedback:'popupFeedback',
        feedbackName:"amSessionComplete",
        feedbackValue:feedBackData
       },'V2');
      SetSessionView('AmfinishedTherapie', feedBackData);
    }
    else
    {
      pushInGoggleTag({
        'event': 'popup_feedback',
        popupQuestion:t('finishedFeedback.pmCompleteTitle'),
        popupFeedback:'popupFeedback',
        feedbackName:"pmSessionComplete",
        feedbackValue:feedBackData
       },'V2');
      SetSessionView('PmfinishedTherapie', feedBackData);
    }
    if (feedBackData === newSession || feedBackData === oldSession) {
      RemoveSessionView(propsValue.videoType);
    }
    propsValue.enablePopup('thankView');
  };
  React.useEffect(() => {
    if (propsValue.videoType === 'AM') {
      setNewSession(t('finishedFeedback.morningNew'));
      setOldSession(t('finishedFeedback.morningOld'));
    } else {
      setNewSession(t('finishedFeedback.eveningNew'));
      setOldSession(t('finishedFeedback.eveningOld'));
    }
  });
  return (
    <>
      <Modal className="brushingModel" show={show} onHide={closeWindowData}>
        <Modal.Header closeButton>
          <Modal.Title>{t('finishedFeedback.modelTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="pop-text">{t('finishedFeedback.iconText')}</p>
          <p className="feedback-font-style"> {t('finishedFeedback.message')}</p>
          <div className="btn-center f-left">
            <button
              shape="round"
              size="large"
              className={
                selectedValue === oldSession ? 'pop-button-ohimpact popup-feedback active' : 'pop-button-ohimpact popup-feedback'
              }
              onClick={() => updateFeedBack(oldSession)}>
              {oldSession}
            </button>
            <button
              shape="round"
              size="large"
              className={
                selectedValue === newSession ? 'pop-button-ohimpact popup-feedback active' : 'pop-button-ohimpact popup-feedback'
              }
              onClick={() => updateFeedBack(newSession)}>
              {newSession}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default FinishedFeedback;
