import React from 'react';
import Slider from 'react-slick';
import card1 from '../../assets/images/images-v3/Prepare-&-Stimulate-card1.png';
import card2 from '../../assets/images/images-v3/Relax-&-Recover-card2.png';
import card3 from '../../assets/images/images-v3/Soothe-and-Relieve-card3.png';
import card4 from '../../assets/images/images-v3/Promote-Relaxation-card4.png';
import { SetLocalStorage } from '../../services/Session';
import { GetThemeValue } from '../../services/Utils';
import { defualtPage } from '../../services/WebsiteUrl';
import { useNavigate } from 'react-router-dom';
import bluecard1 from '../../assets/images/images-v3/Prepare-&-Stimulate-blue-card1.svg';
import bluecard2 from '../../assets/images/images-v3/Relax-&-Recover-blue-card2.svg';
import bluecard3 from '../../assets/images/images-v3/Soothe-and-Relieve-blue-card3.svg';
import bluecard4 from '../../assets/images/images-v3/Promote-Relaxation-bluecard4.svg';
import { useTranslation } from 'react-i18next';
import { pageList } from '../../services/WebsiteUrl';
const getWalkThroughItems = () => [
  {
    id: '1',
    themeGreenSrc: card1,
    themeBlueSrc: bluecard1,
    heading: 'introSlider.one.heading',
    body: 'introSlider.one.body',
    
  },
  {
    id: '2', 
    themeBlueSrc: bluecard2,
    themeGreenSrc: card2,
    heading: 'introSlider.two.heading',
    body: 'introSlider.two.body',
  },
  {
    id: '3',
    themeGreenSrc: card3,
    themeBlueSrc: bluecard3,
    heading: 'introSlider.three.heading',
    body: 'introSlider.three.body',
  },
  {
    id: '4',
    themeGreenSrc: card4,
    themeBlueSrc: bluecard4,
    heading: 'introSlider.four.heading',
    body: 'introSlider.four.body',
  }
];
const NaturalsIntro = () => {
  const [pageListData] = React.useState(pageList()); 
  const { t } = useTranslation('naturals');
  const navigate = useNavigate();
const [slideIndex, setSlideIndex] = React.useState(0);
const pathName = window.location.pathname;
const [theme] = React.useState(GetThemeValue());
const [enableButton, setEnableButton] = React.useState(false);
var settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  beforeChange: (oldIndex, newIndex) => {
    setSlideIndex(newIndex);
  }
};
const BeginSession = () => {
  SetLocalStorage('sesNaturalBegin', 'yes');
  let returnData = defualtPage('v3Session');
  navigate(returnData);
};
const [walkThrough] = React.useState(getWalkThroughItems());
  React.useEffect(() => {
    if(pathName === pageListData['v3HowItWorks'])
    {
      setEnableButton(false);
    }
    else
    {
      setEnableButton(true);
    }
  });
  return (
    <div>
<Slider {...settings}>
    {walkThrough.map((item) => (
      <div className="card-gap" key={item.id + '1'}>
        <div key={item.id} className="crad-slider">
          <div className="card">
          {theme === 'green' ? (
            <img src={item.themeGreenSrc} alt="content description" />
          ) : ('' )}
          
          <h4>{t(item.heading)}</h4>
          <p>{t(item.body)}
          </p>
          {theme === 'blue' ? (
            <img src={item.themeBlueSrc} alt="content description" />
          ) : ('' )}
          </div>
          </div>
      </div>
      
    ))}
  </Slider>
   {enableButton === true && slideIndex === 3 && (
    <div className="btn-position">
      <button className="btn btn-primary btnwidth85 introbtn" onClick={() => BeginSession()}>
      {theme === 'blue' ? t('introSlider.startSession') :  t('introSlider.begin') }
      </button>
    </div>
  )}</div>
  );
};
export default NaturalsIntro;
