/* eslint-disable no-unused-vars */
import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
// let apiCallCount = 0;
let AuthToken = "";
export const createV3Account = async (updateFor, formName) => {

    let authData = await getAuth();
    if (authData) {
        await saveFeedback(formName);
        // let saveFeedbackData = await saveFeedback(formName);
        // if (saveFeedbackData.status !== 200) {
        //     if (apiCallCount < 3) {
        //         apiCallCount++;
        //         createV3Account(updateFor, formName);
        //     }
        // }
    }
}

async function getAuth() {
let url = process.env.REACT_APP_TEST_LOTUS_V3_APIGEE_NODE_API_AUTH_URL;
let authKey = process.env.REACT_APP_TEST_LOTUS_V3_APIGEE_APG_AUTH_TOKEN;
    if((window.location.href.indexOf('nextlotus-uat')> -1) ||  (window.location.href.indexOf('mylotus')> -1 )){
        url = process.env.REACT_APP_PRD_LOTUS_V3_APIGEE_NODE_API_AUTH_URL;
        authKey = process.env.REACT_APP_PRD_LOTUS_V3_APIGEE_APG_AUTH_TOKEN;
    }

    try {
        const config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: url,
            headers: {
                'Authorization': authKey
            }
        };

        const response = await axios.request(config); // Make the Axios request and wait for the response
        let sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
        sessionValue['V3']['accessToken'] = 'Bearer ' + response.data.access_token;
        localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
        AuthToken = 'Bearer ' + response.data.access_token;
        return response;
    } catch (error) {
        return error;
    }
}

async function saveFeedback(userFormName) {
    // eslint-disable-next-line no-unreachable
    let sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
    // if (userFormName == 'email_capture' || userFormName == 'videoFeedback') {
        if (sessionValue['V3']['PrepareStatus'] === undefined && sessionValue['V3'].video.Prepare != undefined) {
            let data = {
                "mindfulApplicationGuides": "Prepare & Stimulate",
                "emotions": sessionValue['V3'].video.Prepare.emotions,
                "otheremotions": sessionValue['V3'].video.Prepare.otheremotions,
                "therapyFeedBack": sessionValue['V3'].video.Prepare.rating,
            }
             await updateFeedBackData(data, "PrepareStatus");

        }
        if (sessionValue['V3']['RelaxSessionStatus'] === undefined && sessionValue['V3'].video.Relax !== undefined) {
            let data = {
                "mindfulApplicationGuides": "Relax & Recover",
                "emotions": sessionValue['V3'].video.Relax.emotions,
                "otheremotions": sessionValue['V3'].video.Relax.otheremotions,
                "therapyFeedBack": sessionValue['V3'].video.Relax.rating,
            }
             await updateFeedBackData(data, 'RelaxSessionStatus');
        }
        if (sessionValue['V3']['SootheSessionStatus'] === undefined && sessionValue['V3'].video.Soothe !== undefined) {
            let data = {
                "mindfulApplicationGuides": "Soothe & Relieve",
                "emotions": sessionValue['V3'].video.Soothe.emotions,
                "otheremotions": sessionValue['V3'].video.Soothe.otheremotions,
                "therapyFeedBack": sessionValue['V3'].video.Soothe.rating,
            }
             await updateFeedBackData(data, 'SootheSessionStatus');
        }
        if (sessionValue['V3']['ReleaseTheTensionSessionStatus'] === undefined && sessionValue['V3'].video.ReleaseTheTension !== undefined) {
            let data = {
                "mindfulApplicationGuides": "Promote Relaxation",
                "emotions": sessionValue['V3'].video.ReleaseTheTension.emotions,
                "otheremotions": sessionValue['V3'].video.ReleaseTheTension.otheremotions,
                "therapyFeedBack": sessionValue['V3'].video.ReleaseTheTension.rating,
            }
             await updateFeedBackData(data, 'ReleaseTheTensionSessionStatus');
        }
    // }
    if (sessionValue['V3']['thanksPopUpFeedBackSessionStatus'] === undefined && sessionValue['V3'].profileThanks !== undefined) {
        let data = {
            "thanksPopUpFeedBack": sessionValue['V3'].profileThanks,
        }
        return await updateFeedBackData(data, 'thanksPopUpFeedBackSessionStatus');
    }
    if (sessionValue['V3']['brandAffinitySessionStatus'] === undefined && sessionValue['V3'].brandAffinity !== undefined) {
        let data = {
            "brandAffinityfeedBack": sessionValue['V3'].brandAffinity,
        }
        return await updateFeedBackData(data, 'brandAffinitySessionStatus');
    }
    if (sessionValue['V3']['productUsageSessionStatus'] === undefined && sessionValue['V3'].productUsage !== undefined) {
        let data = {
            "productfeedBack": sessionValue['V3'].productUsage,
        }

        return await updateFeedBackData(data, 'productUsageSessionStatus');
    }

}

// eslint-disable-next-line no-unused-vars
async function updateFeedBackData(data, sessionsStatus) {

    let url = process.env.REACT_APP_TEST_LOTUS_V3_APIGEE_NODE_API_SAVE_DATA_IN_GIGIYA_URL;
let xApiKey = process.env.REACT_APP_TEST_LOTUS_V3_APIGEE_X_APIKEY;
    if((window.location.href.indexOf('nextlotus-uat')> -1) ||  (window.location.href.indexOf('mylotus')> -1 )){
        url = process.env.REACT_APP_PRD_LOTUS_V3_APIGEE_NODE_API_SAVE_DATA_IN_GIGIYA_URL;
        xApiKey = process.env.REACT_APP_PRD_LOTUS_V3_APIGEE_X_APIKEY;
    }

    let sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
    // let accessToken = sessionValue['V3']['accessToken'];
    data.profile = {
        "firstName": sessionValue['V3'].profile.firstName !== undefined ? sessionValue['V3'].profile.firstName : "",
        "lastName": "",
        "email": sessionValue['V3'].profile.email
    }
    data.lotusFlow = cookies.get('frontend-theme') === "blue" ? "B" : "A"
    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: url,
        // withCredentials: true,
        headers: {

            'Content-Type': 'application/json',
            'Authorization': AuthToken,
            'x-apikey': xApiKey,
        },
        data: data
    };
            sessionValue['V3'][sessionsStatus] = 1;
            localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    return await axios.request(config)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            return error;
        });
}