import { createLiteAccount } from './Apgcalling';
import {createV3Account } from './ApiCalling';

import morningicon1 from '../assets/images/BrushImageLotusV2.png';


const pmVideo = {
  one: {
    morningicon1,
    name: 'One',
    short: 'one',
    keys: '1',
    status: 'pending'
  },
  two: {
    morningicon1,
    name: 'Two',
    short: 'two',
    keys: '2',
    status: 'pending'
  },
  three: {
    morningicon1,
    name: 'Three',
    short: 'three',
    keys: '3',
    status: 'pending'
  },
  four: {
    morningicon1,
    name: 'Four',
    short: 'four',
    keys: '4',
    status: 'pending'
  },
  five: {
    morningicon1,
    name: 'Five',
    short: 'five',
    keys: '5',
    status: 'pending'
  },
  six: {
    morningicon1,
    name: 'Six',
    short: 'six',
    keys: '6',
    status: 'pending'
  },
  seven: {
    morningicon1,
    name: 'Seven',
    short: 'seven',
    keys: '7',
    status: 'pending'
  },
};

export const RemoveFeedBack = (type) => {
  let feedbackPending = JSON.parse(localStorage.getItem('feedbackPending'));
  if (feedbackPending == null) {
    return;
  }else
  {
    if (feedbackPending[type] !== undefined) {
      delete feedbackPending[type];
      if (Object.keys(feedbackPending).length === 0) {
        localStorage.removeItem('feedbackPending', null);
      } else {
        localStorage.setItem('feedbackPending', JSON.stringify(feedbackPending));
      }
    }
  }
};

/* function used for setting Localstorage values

*/
export const SetLocalStorage = (keyName, keyValue) => {
  localStorage.setItem(keyName, keyValue);
  return true;
};
export const GetLocalStorage = (keyName) => {
  return  localStorage.getItem(keyName);
};

export const RemoveSessionView = (keyValue) => {
  const sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
  if (sessionValue != null && sessionValue != undefined) {
    delete sessionValue[keyValue];
    if (Object.keys(sessionValue).length > 0) {
      localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    }
  }
};
export const StartSessionVideo = () => {
  localStorage.setItem('sesBegin', 'yes');
};

export const SetProfileSession = (key, valueData) => {
  const profileValue = JSON.parse(localStorage.getItem('profile'));
  if (profileValue == null) {
    localStorage.setItem('profile', JSON.stringify({ key: valueData }));
  } else {
    profileValue[key] = valueData;
    localStorage.setItem('profile', JSON.stringify(profileValue));
  }
};

export const UpdateGigya = ( updateFor, formName )=>
{
 // alert(updateFor)
  if(updateFor === 'V3')
  {
    let sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
    let sessionData=sessionValue.V3;
    if(sessionValue['VideoWatchVideoCount'] == undefined)
    {
      sessionValue['VideoWatchVideoCount'] = {};
    }
    localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    if (sessionData.profile != null && sessionData.profile !== undefined) {
      createV3Account(updateFor,formName);
    }
  }
  else
  {
    let sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
    if(sessionValue['AMWatchVideoCount'] == undefined)
    {
      sessionValue['AMWatchVideoCount'] = {};
    }
    if(sessionValue['PMWatchVideoCount'] == undefined)
    {
      sessionValue['PMWatchVideoCount'] = {};
    }
    localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    if (sessionValue.profile != null && sessionValue.profile !== undefined) {
      createLiteAccount();
    }
  }
}

export const SetSessionView = (key, valueData) => {
  let sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
  const newValue = {};
  if (sessionValue == null) {
    newValue[key] = valueData;
    localStorage.setItem('sessionValue', JSON.stringify(newValue));
  } else {
    sessionValue[key] = valueData;
    localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
  }
  UpdateGigya('V2','');
};
export const GetCurrentVideo = (type) => {
  const sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
  let feedbackPending = JSON.parse(localStorage.getItem('feedbackPending'));
  if (feedbackPending == null) {
    feedbackPending = {};
  }
  const videoList = pmVideo;
  Object.keys(videoList).forEach((key) => {
    videoList[key].status = 'pending';
  });
  let videoData = {
    allVideo: pmVideo,
    currentVideo: null,
    countCompletedVideo: 0,
    pendingFeedBack: 'no',
    sessionValue,
    profileNeed: 'yes',
    totalCompletedVideo: 0,
  };
  if (sessionValue != null && sessionValue != undefined) {
    if (sessionValue.AM != null && sessionValue.AM !== undefined) {
      videoData.totalCompletedVideo += Object.keys(sessionValue.AM).length;
    }
    if (sessionValue.PM != null && sessionValue.PM !== undefined) {
      videoData.totalCompletedVideo += Object.keys(sessionValue.PM).length;
    }
    if (sessionValue[type] != null && sessionValue[type] !== undefined) {
      const pmValue = sessionValue[type];
      let currentKey = '';
      Object.keys(videoList).forEach((key) => {
        if (!(key in pmValue)) {
          if (currentKey === '') {
            currentKey = key;
          }
        } else if (key in pmValue) {
          videoData.countCompletedVideo += 1;
          videoList[key].status = 'complete';
        }
      });
      videoData.allVideo = videoList;
      if (videoData.countCompletedVideo < 7) {
        if (feedbackPending[type] != null && feedbackPending[type] !== undefined) {
          videoData.currentVideo = videoList[feedbackPending[type]];
          videoData.pendingFeedBack = feedbackPending[type];
          videoList[feedbackPending[type]].className = 'inprocess';
          videoData.allVideo = videoList;
        } else {
          videoData.currentVideo = videoList[currentKey];
          videoList[currentKey].className = 'inprocess';
          videoData.allVideo = videoList;
        }
      }
      return videoData;
    }
    videoData = GetSelectData(videoData, type, feedbackPending, videoList);
    return videoData;
  }
  videoData = GetSelectData(videoData, type, feedbackPending, videoList);
  return videoData;
};

function GetSelectData(videoData, type, feedbackPending, videoList) {
  if (feedbackPending != null && feedbackPending != undefined) {
    if (feedbackPending[type] != null && feedbackPending[type] != undefined) {
      videoData.pendingFeedBack = feedbackPending[type];
      videoList[feedbackPending[type]].status = 'inprocess';
      videoData.currentVideo = videoList[feedbackPending[type]];
    } else {
      videoList.one.status = 'inprocess';
      videoData.allVideo = videoList;
      videoData.currentVideo = videoList.one;
    }
  } else {
    videoList.one.status = 'inprocess';
    videoData.allVideo = videoList;
    videoData.currentVideo = videoList.one;
  }
  return videoData;
}
export const SaveLocalStorage = (type, sessionKey, videoData) => {
  const newValue1 = {};
  newValue1[sessionKey] = videoData;
  const newValue2 = {};
  const feedbackPending = JSON.parse(localStorage.getItem('feedbackPending'));
  const sessionValue = JSON.parse(localStorage.getItem('sessionValue'));
  if (sessionValue != null && sessionValue != undefined) {
    if (sessionValue[type] != null && sessionValue[type] !== undefined) {
      sessionValue[type][sessionKey] = videoData;
      localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    } else {
      sessionValue[type] = {};
      sessionValue[type][sessionKey] = videoData;
      localStorage.setItem('sessionValue', JSON.stringify(sessionValue));
    }
  } else {
    newValue2[type] = newValue1;
    localStorage.setItem('sessionValue', JSON.stringify(newValue2));
  }
  if (feedbackPending != null && feedbackPending != undefined) {
    if (feedbackPending[type] !== undefined) {
      delete feedbackPending[type];
      if (Object.keys(feedbackPending).length === 0) {
        localStorage.removeItem('feedbackPending', null);
      } else {
        localStorage.setItem('feedbackPending', JSON.stringify(feedbackPending));
      }
    }
  }
};

export const SetVoltaNaturaSession = (type,valueData,sessionKey,formName) =>{
  const sessionValue = localStorage.getItem('sessionValue');
  let newSessionValue = {};
  let returnData ;
    if(sessionValue != null && sessionValue != undefined ) 
    {
      const sessionData= JSON.parse(localStorage.getItem('sessionValue'));
      if(sessionData.V3 != null && sessionData.V3 != undefined)
      {
        let v3SessionData = sessionData.V3;
        returnData = setVoltaData(type,valueData,v3SessionData,sessionKey);
      }
      else
      {
        returnData = setVoltaData(type,valueData,{},sessionKey);
      }
      newSessionValue['V3'] = returnData;
    }
    else
    {
      returnData = setVoltaData(type,valueData,{},sessionKey);
      newSessionValue = {'V3':returnData};
    }
    localStorage.setItem('sessionValue',JSON.stringify(newSessionValue));
    if(formName != 'noupdate')
    {
      UpdateGigya('V3', formName); 
    }
    
}

function setVoltaData( type,valueData,sessionData,sessionKey)
{
  if(type === 'video')
  {
   
    if(sessionData[type] != undefined && sessionData[type] != null)
    {
      let newValue = 1 + parseInt(sessionData['videoCount']);
      sessionData['videoCount'] =  newValue;
    }
    else
    {
      sessionData['videoCount'] = '1';
    }
    if(sessionData[type] != undefined && sessionData[type] != null)
    {
      sessionData[type][sessionKey] = valueData;
    }
    else
    {
      sessionData[type]={[sessionKey]: valueData};
    }
  }
  else
  {
    sessionData[type] = valueData;
  }
  return sessionData;
}
export const GetVoltaraNaturalFeedback = () => {
  return (localStorage.getItem('VoltaraNaturals'));
};

export const SetVoltaraNaturalFeedBack = (feedBackData)=>{
localStorage.setItem('VoltaraNaturals',JSON.stringify(feedBackData));
};

export const RemoveVoltaraNaturalFeedback =()=>{
  localStorage.removeItem('VoltaraNaturals', null);
};

export const GetVoltaraNaturalSession =() =>{
  let getSessionData = localStorage.getItem('sessionValue');
  if(getSessionData!= null && getSessionData != undefined)
  {
    let sessionFinalValue = JSON.parse(getSessionData);
    if(sessionFinalValue.V3 != null && sessionFinalValue.V3 != undefined)
    {
        return sessionFinalValue.V3;
    }
    else
    {
      return null;
    }
  }
  else
  {
    return null;
  }
}

