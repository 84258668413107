import React from 'react';
//import mediaprev from '../assets/images/media-prev.svg';
import mediaprev from '../assets/images/images-v3/media-prev.svg';
import medianext from '../assets/images/media-next.svg';
import mediaplay from '../assets/images/media-play.svg';
import mediapause from '../assets/images/media-pause.svg';
import mediabluenext from '../assets/images/media-next-blue.svg';
import mediablueprev from '../assets/images/media-prev-blue.svg';
import bluesubtitleactive from '../assets/images/images-v3/blue_subtitle_active.jpg';
import bluesubtitle from '../assets/images/images-v3/blue_subtitle.svg'; 
import greensubtitleactive from '../assets/images/images-v3/green_subtitle_active.jpg';
import greensubtitle from '../assets/images/images-v3/green_subtitle.jpg';
import { GetThemeValue } from '../services/Utils';
import {pushInGoggleTag} from '../services/DataLayerGa';
import {SetLocalStorage} from '../services/Session';
const VideoPlayer = (propsValue) => {
  const [theme] = React.useState(GetThemeValue());
  const videoRef = React.useRef(null);
  const [duration,setDuration] = React.useState(0);
 // const [currentVideo,setCurrentVideo] = React.useState(propsValue.videoId);
  const [trackSrc] = React.useState('../assets/video/VoltaraNaturals/' +propsValue.videoId+'.vtt');
  const [videoSrc] = React.useState('../assets/video/VoltaraNaturals/' +propsValue.videoId+'.mp4');
  //const [percentage , setPercentage] = React.useState('0%');
 const [videoPlayStatus,setVideoPlayStatus] = React.useState('no');
 
 const [currentTime,setCurrentTime] = React.useState(0);
 const [trackPlayStatus,setTrackPlayStatus] = React.useState('OFF');
 const [subtitleButtonName,setSubtitleButton] =React.useState('');
 const progressBarRef = React.useRef(null);
 const handleScrubberChange = (e) => {
    
  const newTime = parseFloat(e.target.value);
 // alert(e.target.value);
  setCurrentTime(newTime);
  //setCurrentTime(newTime);
  if (videoRef.current) {
    videoRef.current.currentTime = newTime;
  }
};
  React.useEffect(() => {
    //  setPercentage(0);//alert(percentage);
      videoRef.current.load();
      videoTimer();
      subtitleButton('OFF');
      const autoplayDelay = 1000; // 3 seconds delay before autoplay (adjust as needed)

      const playVideo = () => {
          videoRef.current.play().then(() => {
          videoRef.current.muted = false;
          setVideoPlayStatus('yes');
        }).
        catch((error) => {
            // Autoplay may be blocked by the browser, handle the error here
           setVideoPlayStatus('no');
            console.error('Autoplay failed:', error);
          });
      };
  
      const timeoutId = setTimeout(playVideo, autoplayDelay);
      return () => {
        clearTimeout(timeoutId); // Clean up the timeout if the component unmounts before autoplay
      };
  
  },[propsValue.videoId]);
  const playPauseVideo=()=> {
    if (videoRef.current && videoPlayStatus === 'yes'){
      setVideoPlayStatus('no');
      videoRef.current.pause();
    }
    else
    {
      setVideoPlayStatus('yes');
      videoRef.current.play();
    }
  };
  const handleProgressBarClick = (event) => {
  const progressBar = progressBarRef.current;
  const progressRect = progressBar.getBoundingClientRect();
const clickPosition = event.clientX - progressRect.left;
const progressBarWidth = progressBar.offsetWidth;
const currentTimeData = (clickPosition / progressBarWidth) * videoRef.current.duration;
videoRef.current.currentTime = currentTimeData;
  };
  const handleTimeUpdate = () => {
     if(videoRef.current != null && videoRef.current != undefined)
     {  setDuration(videoRef.current.duration);
      setCurrentTime(videoRef.current.currentTime );
        if(videoRef.current.currentTime != 0 && isNaN(videoRef.current.duration) === false){
       // let t = Math.floor(videoRef.current.currentTime / videoRef.current.duration * 100 );
      //  setPercentage(t +'%');
    }
  }
};
  const videoTimer=()=>{
    if(videoRef != null && videoRef != undefined)
    {
          videoRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }
    else
    {
      videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
    }
  };
  const onPlay = () => {
    pushInGoggleTag({'event': 'video-status'},'V3');
  }
  const handleSeekBackward = () => {
    if(videoRef != null )
    {
      if (videoRef.current) {
        videoRef.current.currentTime -= 5; // Seek 10 seconds backward
      }
    }
  };

  const handleToggleSubtitles = () => {
    if(trackPlayStatus === 'OFF' )
    {
      setTrackPlayStatus('ON');
      SetLocalStorage('subtitle','ON');
      subtitleButton('ON');
    }
    else{
      setTrackPlayStatus('OFF');
      SetLocalStorage('subtitle','OFF');
      subtitleButton('OFF');
    }
    
   
  };
  const handleSeekForward = () => {
    if(videoRef != null )
    {
      if (videoRef.current) {
        videoRef.current.currentTime += 5; // Seek 10 seconds forward
      }
    }
  };

  const endVideoCallback = () => {
    propsValue.endPlayerCallback();
   
  };

  const subtitleButton =(status)=>{
    if(theme =='green' && status === 'ON') 
    {
      setSubtitleButton(greensubtitleactive);
    }
    else if(theme == 'blue' && status === 'ON' )
    {
      setSubtitleButton(bluesubtitleactive);
    }
    if(theme =='green' && status === 'OFF') 
    {
      setSubtitleButton(greensubtitle);
    }
    else if(theme == 'blue' && status === 'OFF' )
    {
      setSubtitleButton(bluesubtitle);
    }
  };
  return (
    <>
        <video
        videoversion={propsValue.videoVersion}
        poster={propsValue.videoId}
        ref={videoRef}
        className="video-size"
        controlsList="nodownload"
        id="videokey"
        width="100%"
        height="auto"
        playsInline
        muted={false}
        autoPlay={true}
        onPlay={() => onPlay()}
        onEnded={() => endVideoCallback()} preload="auto">
        <source
        src={videoSrc}
        type="video/mp4"
        />
         {trackPlayStatus === 'ON'  && (
           <track src={trackSrc} kind="captions" label="English" default />
        )}
       
        </video>
        <div className="subtitles" onClick={handleToggleSubtitles}>
          <div className={trackPlayStatus === 'ON' ? 'subtitles-btn active' :'subtitles-btn'}><img src={subtitleButtonName}/></div>
        </div>
        <div id="progressBar" ref={progressBarRef} className={theme==='green'?'progressBar-green':'progressBar-blue'} onClick={handleProgressBarClick}>
  
  <input type="range" id="scrubSlider" className="scrubSlider1"  onChange={handleScrubberChange} onmin="0" value={currentTime} max={duration} step="0.1"/>
  <div class="range-slider">
    <input type="range" min="0" max={duration} value="0" id="range"  onChange={handleScrubberChange} onmin="0" value={currentTime} max={duration}step="0.1" /> 
  
</div>
</div>
   {/* <div className="progress" ref={progressBarRef} onClick={handleProgressBarClick}>
  <div   className="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: percentage }} ></div>
    </div>*/}
      <div className="play-control">
    <div onClick={() => handleSeekBackward()}><img width={42} src={theme==='green'?mediaprev:mediablueprev} alt="Media Prev"></img></div>
    <div className="media-play" onClick={() => playPauseVideo()}><img src={videoPlayStatus=='yes'? mediaplay:mediapause} alt="Media Play"></img></div> 
    <div  onClick={() => handleSeekForward()}><img width={42} src={theme==='green'?medianext:mediabluenext} alt="Media Next"></img></div>
    </div>
    </>
  );
};

export default VideoPlayer;