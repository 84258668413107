import React from 'react';
import finsihedThankImg from '../../assets/images/lotus-illustration.svg';
import profileThankImg from '../../assets/images/GiftBox.png';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
const FinishedThank = (propsValue) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(true);
  const [contentData, setContentData] = React.useState('');
  const closeWindowData = () => {
    propsValue.updateSelectedValue(propsValue.currentSelectedSession);
    setShow(false);
  };
  React.useEffect(() => {
    if (propsValue.popTemplate === 'thankView') {
      setContentData(t('finishThanks.iconText'));
    } else {
      setContentData(t('finishThanks.emailText'));
    }
  },[propsValue.popTemplate, t]);
  return (
    <>
      <Modal className="brushingModel" show={show} onHide={closeWindowData}>
        <Modal.Header closeButton>
          <Modal.Title>{t('finishThanks.modelTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{contentData}</p>
          <img
            src={propsValue.popTemplate === 'profileThanksView' ? profileThankImg : finsihedThankImg}
            alt="Thank You Gift"></img>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default FinishedThank;
